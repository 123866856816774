import React from "react";
import { Bar } from "react-chartjs-2";
import "./Graphs.css";

export default function BarChart(props) {
  return (
    <div className="inherit-parent-height inherit-parent-width flex-justify-content-center flex-align-items-center">
      <Bar
        data={props.data}
        options={props.options}
        plugins={props.plugins}
        className="inherit-parent-height inherit-parent-width"
      />
    </div>
  );
}
