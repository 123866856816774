import React, { useState } from "react";
import ControlButton from "../ControlButton/ControlButton";
import CustomScrollBar from "../CustomScrollBar/CustomScrollBar";
import { setErrorStatus } from "../../Redux/status/action";
import { isValidArray, isValidObject } from "../../Services/validators";
import Button from "../Button/Button";
import ToolTip from "../ToolTIp/ToolTip";
import { ErrorIcon } from "../../Assets/assets";

export default function EmployeeCreate(props) {
  const [activeOption, setActiveOption] = useState("DETAILS");
  const [formData, setFormData] = useState({
    name: "",
    phoneNumber: "+91",
    file: null,
  });
  const [hover, setHover] = useState(false);
  const [selectedRoles, setSelectedRoles] = useState([]);

  const handleFileChange = async (event) => {
    if (event.target.files) {
      const fileType = event.target.files[0]?.type;
      const validFileTypes = ["image/jpeg", "image/jpg", "image/png"];

      if (!validFileTypes.includes(fileType)) {
        return setErrorStatus({
          code: "custom",
          message: "Kindly upload a valid File",
        });
      } else if (
        typeof event.target.files[0]?.size === "number" &&
        event.target.files[0].size > 250000
      ) {
        setErrorStatus({
          code: "input/file-size-exceeded",
          message: "Document size should be less than 5MB",
        });
      } else {
        try {
          const fileBlob = await uploadFile(event.target.files[0]);
          setFormData({ ...formData, file: fileBlob });
        } catch (error) {
          console.error("Error uploading file:", error);
          setErrorStatus({
            code: "upload_error",
            message: "There was an error uploading the file.",
          });
        }
      }
    }
  };

  // const handleDisable = (header) => {
  //   if (
  //     (header === "DETAILS" || header === "ROLES") &&
  //     formData.name &&
  //     formData.phoneNumber &&
  //     formData.file
  //   ) {
  //     return false;
  //   } else {
  //     return true;
  //   }
  // };

  const isValidPhoneNumber = (data) => {
    let value;
    if (data.length <= 3) {
      value = { status: true };
    } else if (isEmployeePresent(data)) {
      value = {
        status: false,
        message: "Employee already exists",
      };
    } else if (/^[+]{1}[0-9]{2}[0-9]{10}$/.test(data)) {
      value = { status: true };
    } else {
      value = {
        status: false,
        message: "Phone number must be 10 digits",
      };
    }
    return value;
  };

  const isEmployeePresent = (phoneNumber) => {
    console.log(props.employeeData, "props.employeeData", phoneNumber);
    if (isValidObject(props.employeeData)) {
      const data = Object.values(props.employeeData)?.find(
        (data) => data.phoneNumber === phoneNumber
      );
      console.log(data, "asdasdasdas");
      return isValidObject(data);
    }
  };

  return (
    <div
      data-cy="employee-modal"
      className={`modal-frame background-color-dark inherit-parent-height inherit-parent-width display-flex padding-larger min-width-170px `}
    >
      {/* Header-section */}
      <div
        className="inherit-parent-height padding-right-medium display-flex flex-direction-column"
        data-cy="header-section"
      >
        {["DETAILS"].map((header, index) => (
          <ControlButton
            key={index}
            text={header}
            selected={activeOption === header}
            onClick={() => {
              setActiveOption(header);
            }}
            // disable={handleDisable(header)}
            theme={props.theme}
            dataCy={
              activeOption === header
                ? `header-selected-${header?.toLowerCase()}`
                : `header-select-${header?.toLowerCase()}`
            }
          />
        ))}
      </div>
      <div
        style={{
          marginLeft: "48px",
        }}
        className={`parent-width-sub-156px inherit-parent-height display-flex flex-direction-column font-size-default position-relative `}
        data-cy="location-body-section"
      >
        <CustomScrollBar horizontal="left">
          <div
            style={{
              zIndex: 1,
            }}
            className="inherit-parent-width padding-top-default padding-bottom-default margin-bottom-medium text-align-left display-flex flex-direction-column position-sticky top-0 background-color-dark"
          >
            <div
              className="font-size-larger text-uppercase padding-bottom-default"
              data-cy={"employee-creation-section"}
            >
              EMPLOYEE CREATION
            </div>
          </div>

          {/* location choice */}
          {activeOption === "DETAILS" && (
            <div
              style={{
                height: "calc(100% - 70px)",
              }}
              className="inherit-parent-width display-flex flex-direction-column  flex-justify-content-space-between font-size-default"
              data-cy="location-description-section"
            >
              {[
                {
                  title: "PROFILE",
                  value: (
                    <div>
                      {formData.file ? (
                        <form
                          style={{
                            width: "96px",
                            height: "96px",
                          }}
                          onMouseEnter={() => {
                            setHover(true);
                          }}
                          onMouseLeave={() => {
                            setHover(false);
                          }}
                          className="cursor-pointer position-relative"
                          onChange={handleFileChange}
                        >
                          <label>
                            <input
                              type="file"
                              accept=".jpg,.jpeg,.png"
                              name={"fileUploaderInput"}
                              onClick={(event) => {
                                event.target.value = "";
                              }}
                              className="visibility-hidden position-absolute"
                            />
                            <img
                              style={{
                                width: "96px",
                                height: "96px",
                              }}
                              src={URL.createObjectURL(formData.file)}
                              alt="profileImage"
                            />

                            {hover && (
                              <div
                                style={{
                                  position: "absolute",
                                  opacity: "85%",
                                  top: "0px",
                                }}
                              >
                                <DefaultEditPic />
                              </div>
                            )}
                          </label>
                        </form>
                      ) : (
                        <form
                          style={{
                            width: "96px",
                            height: "96px",
                          }}
                          className="margin-bottom-default cursor-pointer"
                          onChange={handleFileChange}
                        >
                          <label>
                            <input
                              type="file"
                              accept=".jpg,.jpeg,.png"
                              name={"fileUploaderInput"}
                              onClick={(event) => {
                                event.target.value = "";
                              }}
                              className="visibility-hidden position-absolute"
                            />
                            <DefaultAddPic />
                          </label>
                        </form>
                      )}
                    </div>
                  ),
                },
                {
                  title: "NAME",
                  value: (
                    <input
                      placeholder={"ENTER EMPLOYEE NAME"}
                      onChange={(event) => {
                        setFormData({
                          ...formData,
                          [event.target.name]: event.target.value,
                        });
                      }}
                      data-cy={`employee-create-name-input-box`}
                      className={`background-color-dark inherit-parent-width font-color-white border-none font-size-medium `}
                      name="name"
                      value={formData.name}
                    />
                  ),
                },
                {
                  title: "PHONE NUMBER",
                  value: (
                    <div className="display-flex">
                      <input
                        placeholder={"ENTER EMPLOYEE PHONE NUMBER"}
                        onChange={(event) => {
                          setFormData({
                            ...formData,
                            [event.target.name]: event.target.value,
                          });
                        }}
                        data-cy={`employee-create-phone-number-input-box`}
                        className={`background-color-dark inherit-parent-width font-color-white border-none font-size-medium`}
                        name="phoneNumber"
                        value={formData.phoneNumber}
                      />
                      {isValidPhoneNumber(formData.phoneNumber)?.status ===
                      false ? (
                        <div className="padding-horizontal-small cursor-pointer display-flex">
                          <ToolTip
                            text={
                              isValidPhoneNumber(formData.phoneNumber)?.message
                            }
                            position="bottom"
                            theme={props.theme}
                            dataCy={props.data?.dataCy}
                          >
                            <ErrorIcon width="16px" height="16px" />{" "}
                          </ToolTip>
                        </div>
                      ) : (
                        <div className="padding-horizontal-small cursor-pointer display-flex">
                          <ErrorIcon
                            width="16px"
                            height="16px"
                            color="#020d23"
                          />{" "}
                        </div>
                      )}
                    </div>
                  ),
                },
              ].map((data, index) => (
                <div
                  className="width-70-percentage"
                  key={`${index}-${data?.title}`}
                >
                  <Fields
                    width="180px"
                    title={data?.title}
                    data={data?.value}
                  />
                </div>
              ))}
              <div className="inherit-parent-width flex-justify-content-end">
                <Button
                  boxShadow={false}
                  type="button"
                  variant="primary"
                  text="CREATE"
                  disabled={
                    formData?.name && formData.phoneNumber ? false : true
                  }
                  className="margin-right-small width-200px"
                  onClick={() => {
                    props.closeModal();
                    props.createEmployee({
                      name: formData?.name,
                      phoneNumber: formData.phoneNumber,
                      ...(formData.file ? { file: formData.file } : {}),
                      ...(selectedRoles ? { roles: selectedRoles } : {}),
                    });
                  }}
                  data-cy={"next-button"}
                />
              </div>
            </div>
          )}

          {activeOption === "ROLES" && (
            <div
              className="inherit-parent-width"
              data-cy="location-moreInfo-option"
              style={{
                height: "calc(100% - 100px)",
              }}
            >
              <div className="inherit-parent-width inherit-parent-height display-flex flex-direction-column flex-justify-content-space-between">
                <Fields
                  title={"ROLES"}
                  data={
                    <div>
                      {Object.values(props.roles).map((data, index) => (
                        <div
                          key={index}
                          style={{
                            width: "200px",
                          }}
                          className="padding-left-large padding-bottom-default display-flex flex-justify-content-space-between"
                        >
                          <div>{data.name}</div>
                          <div
                            className="cursor-pointer"
                            onClick={() => {
                              if (
                                isValidArray(selectedRoles) &&
                                selectedRoles.includes(data.documentId)
                              ) {
                                setSelectedRoles(
                                  selectedRoles.filter(
                                    (roles) => roles !== data.documentId
                                  )
                                );
                              } else {
                                setSelectedRoles([
                                  ...selectedRoles,
                                  data.documentId,
                                ]);
                              }
                            }}
                          >
                            {selectedRoles.includes(data.documentId) ? (
                              <div data-cy={`${data.name}-selected`}>
                                <SelectedSquare />
                              </div>
                            ) : (
                              <div data-cy={`${data.name}-select`}>
                                <SelectSquare />
                              </div>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  }
                />
              </div>
              <div className="inherit-parent-width flex-justify-content-end">
                <Button
                  boxShadow={false}
                  type="button"
                  variant="primary"
                  text="CREATE"
                  className="margin-right-small width-200px"
                  onClick={() => {
                    props.closeModal();
                    props.createEmployee({
                      name: formData?.name,
                      phoneNumber: formData.phoneNumber,
                      file: formData.file,
                      roles: selectedRoles,
                    });
                  }}
                  data-cy={"next-button"}
                />
              </div>
            </div>
          )}
        </CustomScrollBar>
      </div>
    </div>
  );
}

const Fields = (props) => {
  return (
    <div className="display-flex flex-justify-content-space-between padding-vertical-medium">
      <div className="display-flex">
        <div
          className="white-space-no-wrap"
          style={{ width: props.width }}
          data-cy={`${props.title}`}
        >
          {props.title}
        </div>
        <div className="padding-horizontal-medium">:</div>
      </div>
      <div
        className="text-align-left inherit-parent-width white-space-no-wrap text-uppercase"
        data-cy={props.dataCy || props.data}
      >
        {props.data}
      </div>
    </div>
  );
};

const uploadFile = async (file) => {
  const fileBlob = await new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      if (reader.error) {
        reject(reader.error);
        return;
      }

      const fileBlob = new Blob([reader.result], { type: file.type });
      resolve(fileBlob);
    };

    reader.readAsArrayBuffer(file);
  });

  return fileBlob;
};

const DefaultAddPic = () => {
  return (
    <svg
      width="96"
      height="96"
      viewBox="0 0 96 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2615_703)">
        <rect width="96" height="96" fill="#262626" clipopacity="0.4" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M47.8 47.3V41H49.2V47.3H55.5V48.7H49.2V55H47.8V48.7H41.5V47.3H47.8Z"
          fill="white"
        />
        <path d="M6.94727 96H0.000629902V94.2633H6.94727V96Z" fill="white" />
        <path
          d="M1.73729 89.0534V96H0.000629902V89.0534H1.73729Z"
          fill="white"
        />
        <path
          d="M88.9473 95.9473H95.8939V94.2106H88.9473V95.9473Z"
          fill="white"
        />
        <path
          d="M94.1572 89.0006V95.9473H95.8939V89.0006H94.1572Z"
          fill="white"
        />
        <path d="M88.9473 0H95.8939V1.73666H88.9473V0Z" fill="white" />
        <path d="M94.1572 6.94664V0H95.8939V6.94664H94.1572Z" fill="white" />
        <path d="M6.94727 0H0.000629902V1.73666H6.94727V0Z" fill="white" />
        <path
          d="M1.73729 6.94664V0H0.000629902V6.94664H1.73729Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_2615_703">
          <rect width="96" height="96" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const DefaultEditPic = () => {
  return (
    <svg
      width="96"
      height="96"
      viewBox="0 0 96 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2615_703)">
        <rect width="96" height="96" fill="#262626" />
        <path
          d="M50.5588 42.7884L53.0294 45.2589M48.9118 54.3178H55.5M42.3235 51.0236L41.5 54.3178L44.7941 53.4942L54.3355 43.9528C54.6443 43.644 54.8178 43.2251 54.8178 42.7884C54.8178 42.3516 54.6443 41.9328 54.3355 41.6239L54.1939 41.4822C53.885 41.1735 53.4662 41 53.0294 41C52.5927 41 52.1738 41.1735 51.8649 41.4822L42.3235 51.0236Z"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M6.94727 96H0.000629902V94.2633H6.94727V96Z" fill="white" />
        <path
          d="M1.73729 89.0534V96H0.000629902V89.0534H1.73729Z"
          fill="white"
        />
        <path
          d="M88.9473 95.9473H95.8939V94.2106H88.9473V95.9473Z"
          fill="white"
        />
        <path
          d="M94.1572 89.0006V95.9473H95.8939V89.0006H94.1572Z"
          fill="white"
        />
        <path d="M88.9473 0H95.8939V1.73666H88.9473V0Z" fill="white" />
        <path d="M94.1572 6.94664V0H95.8939V6.94664H94.1572Z" fill="white" />
        <path d="M6.94727 0H0.000629902V1.73666H6.94727V0Z" fill="white" />
        <path
          d="M1.73729 6.94664V0H0.000629902V6.94664H1.73729Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_2615_703">
          <rect width="96" height="96" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const SelectSquare = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="13" height="13" stroke="white" />
    </svg>
  );
};
const SelectedSquare = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="13" height="13" stroke="white" />
      <rect x="3.5" y="3.5" width="7" height="7" fill="white" />
      <rect x="3.5" y="3.5" width="7" height="7" stroke="white" />
    </svg>
  );
};
