import React, { useMemo, useState } from "react";
import SearchFallBackIcon from "../../Assets/SearchFallBackIcon.svg";
import { isValidArray, isValidObject } from "../../Services/validators";
import {
  alignPhoneNumber,
  capitalizeFirstLetter,
  dateAndTimeConverter,
  isDigit,
} from "../../Utils/constants";
import Table from "../Table/Table";

export default function ProcedureHistoryTable(props) {
  const [sortConfig, setSortConfig] = useState({
    title: "",
    ascending: true,
  });

  const sortedProcedureHistory = useMemo(() => {
    const { procedures, locations, employees } = props;
    const { history } = procedures;
    const { data: locationData } = locations;
    const { data: procedureData } = procedures;
    const { data: employeeData } = employees;

    if (sortConfig?.title && isValidObject(history)) {
      return Object.values(history).sort((a, b) => {
        let nameA, nameB;

        switch (sortConfig.title) {
          case "PCT NO":
            nameA = locationData?.[a.locationId]?.PCTNo?.toUpperCase();
            nameB = locationData?.[b.locationId]?.PCTNo?.toUpperCase();
            break;
          case "PROCEDURE NAME":
            nameA = procedureData?.[a.procedureId]?.title?.toUpperCase();
            nameB = procedureData?.[b.procedureId]?.title?.toUpperCase();
            break;
          case "COMPLETED BY":
            nameA = employeeData?.[a.employeeId]?.phoneNumber;
            nameB = employeeData?.[b.employeeId]?.phoneNumber;
            break;
          case "PROCEDURE TYPE":
            nameA = a.procedureType?.toUpperCase();
            nameB = b.procedureType?.toUpperCase();
            break;
          case "COMPLETED AT":
            nameA = a.createdAt;
            nameB = b.createdAt;
            break;
          default:
            return 0;
        }

        nameA = nameA || "N/A";
        nameB = nameB || "N/A";

        if (isDigit(nameA) && isDigit(nameB)) {
          nameA = parseInt(nameA);
          nameB = parseInt(nameB);
        }

        if (nameA < nameB) {
          return sortConfig.ascending ? -1 : 1;
        }
        if (nameA > nameB) {
          return sortConfig.ascending ? 1 : -1;
        }
        return 0;
      });
    }

    return history ? Object.values(history) : [];
    // eslint-disable-next-line
  }, [
    props.procedures.history,
    sortConfig,
    props.locations,
    props.procedures,
    props.employees,
  ]);

  const tableHeadings = [
    { title: "PCT NO", width: "80px" },
    { title: "PROCEDURE NAME", width: "220px" },
    { title: "COMPLETED BY", width: "180px" },
    { title: "PROCEDURE TYPE", width: "100px", alignItemCenter: true },
    { title: "COMPLETED AT", width: "180px", alignItemCenter: true },
  ];

  const handleSort = (title) => {
    setSortConfig((prevConfig) => ({
      title,
      ascending: prevConfig.title === title ? !prevConfig.ascending : true,
    }));
  };

  return (
    <div
      className="remaining-body-height-from-footer inherit-parent-width"
      style={{
        minWidth: "700px",
      }}
    >
      {isValidArray(sortedProcedureHistory) && (
        <Table
          showSummary={false}
          headers={tableHeadings}
          onScrollReachedEnd={() => {
            // if (props.complaints.paginationLoading === false) {
            props.getProcedureHistoryNextPage();
            // }
          }}
          data={sortedProcedureHistory?.map((data) => ({
            documentId: data?.documentId,
            PCTNo: props.locations.data[data.locationId]?.PCTNo?.padStart(
              2,
              "0"
            ),
            title: props.procedures.data?.[data.procedureId]?.title,
            completedBy: isValidObject(props.employees.data?.[data.employeeId])
              ? `${alignPhoneNumber(
                  props.employees.data?.[data.employeeId]?.phoneNumber
                )}, ${props.employees.data?.[data.employeeId]?.name}`
              : "N/A",
            procedureType: capitalizeFirstLetter(data.procedureType),
            createdAt: data?.createdAt
              ? `${dateAndTimeConverter(
                  data?.createdAt,
                  "Time"
                )}, ${dateAndTimeConverter(data?.createdAt, "thirdDate")}`
              : "N/A",
          }))}
          onClick={(data) => {
            props.setSelectedTableId(data?.documentId);
          }}
          handleSort={(data) => {
            handleSort(data);
          }}
          getField={proceduresFields}
          sort={sortConfig}
          theme={props.theme}
        />
      )}

      {!isValidArray(sortedProcedureHistory) && (
        <div
          className="parent-height-sub-42px inherit-parent-width font-size-default display-flex flex-direction-column flex-justify-content-center flex-align-items-center"
          data-cy="no-search-or-filter-fallback"
        >
          <img src={SearchFallBackIcon} alt="no filter result found" />
          <div className=" padding-top-larger">NO MATCHING RESULTS</div>
          <div className=" padding-top-large">
            There wasn’t any result matching to the search and filter
          </div>
        </div>
      )}
    </div>
  );
}

function proceduresFields(data) {
  switch (data) {
    case "PCT NO":
      return "PCTNo";
    case "PROCEDURE NAME":
      return "title";
    case "COMPLETED BY":
      return "completedBy";
    case "PROCEDURE TYPE":
      return "procedureType";

    case "COMPLETED AT":
      return "createdAt";

    default:
      return data?.toLowerCase();
  }
}
