import React, { useMemo } from "react";
import BarChart from "../../Components/Graphs/BarChart";

import { DoughnutChart } from "../../Components/Graphs/DoughnutChart";
import { CornerLIcon, TableIcon } from "../../Assets/assets";
import {
  stripeBarChartPlugin,
  stripeDoughnutChartPlugin,
} from "../../Screens/Test/stripePlugin";
import footerImage from "../../Assets/TableFooter.svg";

export default function LocationSummary(props) {
  const locationDataTotals = useMemo(() => {
    return props.hits.reduce(
      (acc, item) => {
        acc.optimal += item.status === "Optimal" || 0;
        acc.moderate += item.status === "Moderate" || 0;
        acc.critical += item.status === "Critical" || 0;
        return acc;
      },
      {
        optimal: 0,
        moderate: 0,
        critical: 0,
      }
    );
  }, [props.hits]);

  const barChartData = {
    labels: ["Jan", "Feb", "March", "April", "May", "June", "July"],
    datasets: [
      {
        label: "Dataset 1",
        backgroundColor: "white",
        borderColor: "black",
        borderWidth: 0,
        data: [65, 59, 80, 81, 56, 55, 40],
        barThickness: 16,
      },
    ],
  };

  const barChartOptions = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
        callbacks: {
          label: function(context) {
            const label = context.label || "";
            const value = context.raw || "";
            return `${label}: ${value}`;
          },
        },
        position: "nearest",
        external: function(context) {
          const tooltip = context.tooltip;
          if (tooltip.opacity === 0) {
            return;
          }

          // Custom tooltip element
          let tooltipEl = document.getElementById("chartjs-tooltip");
          if (!tooltipEl) {
            tooltipEl = document.createElement("div");
            tooltipEl.id = "chartjs-tooltip";
            tooltipEl.style.position = "absolute";
            // tooltipEl.style.backgroundColor = "rgba(0,0,0,0.7)";
            tooltipEl.style.color = "#fff";
            tooltipEl.style.padding = "5px";
            tooltipEl.style.borderRadius = "3px";
            tooltipEl.style.pointerEvents = "none";
            document.body.appendChild(tooltipEl);
          }

          if (tooltip.opacity === 0) {
            tooltipEl.style.opacity = 1;
            return;
          }

          tooltipEl.innerHTML = tooltip.body
            .map((bodyItem) => bodyItem.lines)
            .join("<br>");

          const chartPosition = context.chart.canvas.getBoundingClientRect();
          tooltipEl.style.left = `${chartPosition.left +
            window.pageXOffset +
            (tooltip.caretX - 10)}px`;
          tooltipEl.style.top = `${chartPosition.top +
            window.pageYOffset -
            tooltip.height -
            10}px`;
          tooltipEl.style.opacity = 1;
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
          drawBorder: true,
          borderColor: "white",
          borderWidth: 2,
        },
        ticks: {
          display: true,
          color: "white",
        },
      },
      y: {
        grid: {
          display: false,
          drawBorder: true,
          borderColor: "white",
          borderWidth: 2,
        },
        ticks: {
          display: true,
          color: "white",
        },
      },
    },
  };

  const doughnutChartData = {
    labels: ["OPTIMAL", "MODERATE", "CRITICAL"],
    datasets: [
      {
        label: "Complaint status",
        data: [
          locationDataTotals.optimal || 0,
          locationDataTotals.moderate || 0,
          locationDataTotals.critical || 0,
        ],

        backgroundColor: [
          locationDataTotals.optimal > 0 ? "#3676D6" : "#c2c2c2",
          locationDataTotals.moderate > 0 ? "#FFFFFF" : "#c2c2c2",
          locationDataTotals.critical > 0 ? "#FF6C6C" : "#c2c2c2",
        ],
        borderColor: [
          locationDataTotals.optimal > 0 ? "#3676D6" : "#c2c2c2",
          locationDataTotals.moderate > 0 ? "#FFFFFF" : "#c2c2c2",
          locationDataTotals.critical > 0 ? "#FF6C6C" : "#c2c2c2",
        ],
        borderWidth: 1,
      },
    ],
  };

  const doughnutChartOptions = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
        callbacks: {
          label: function(context) {
            const label = context.label || "";
            const value = context.raw || "";
            return `${label}: ${value}`;
          },
        },
        position: "average",
        external: function(context) {
          const tooltip = context.tooltip;
          if (tooltip.opacity === 0) {
            return;
          }

          // Custom tooltip element
          let tooltipEl = document.getElementById("chartjs-tooltip");
          if (!tooltipEl) {
            tooltipEl = document.createElement("div");
            tooltipEl.id = "chartjs-tooltip";
            tooltipEl.style.position = "absolute";
            // tooltipEl.style.backgroundColor = "rgba(0,0,0,0.7)";
            tooltipEl.style.color = "#fff";
            tooltipEl.style.padding = "5px";
            tooltipEl.style.borderRadius = "3px";
            tooltipEl.style.pointerEvents = "none";
            document.body.appendChild(tooltipEl);
          }

          if (tooltip.opacity === 0) {
            tooltipEl.style.opacity = 1;
            return;
          }

          tooltipEl.innerHTML = tooltip.body
            .map((bodyItem) => bodyItem.lines)
            .join("<br>");

          const chartPosition = context.chart.canvas.getBoundingClientRect();
          tooltipEl.style.left = `${chartPosition.left +
            window.pageXOffset +
            (tooltip.caretX - 10)}px`;
          tooltipEl.style.top = `${chartPosition.top +
            window.pageYOffset -
            tooltip.height -
            10}px`;
          tooltipEl.style.opacity = 1;
        },
      },
    },
    cutout: "50%",
    responsive: true,
    maintainAspectRatio: false,
  };

  return (
    <div
      className="inherit-parent-height remaining-body-height-from-header flex-justify-content-center flex-align-items-center inherit-parent-width overflow-scroll"
      style={{
        minWidth: "80%",
      }}
    >
      <div
        style={{
          minWidth: "100%",
        }}
        className="inherit-parent-height inherit-parent-width padding-larger flex-justify-content-center flex-align-items-center"
      >
        <div className="inherit-parent-width">
          <div className="display-flex">
            <div
              style={{
                minWidth: "33%",
                width: "33%",
              }}
              onMouseLeave={() => {
                let tooltipEl = document.getElementById("chartjs-tooltip");
                if (tooltipEl) {
                  tooltipEl.style.opacity = 0;
                }
              }}
            >
              <div className="font-size-medium padding-bottom-large text-align-center">
                KPI GRAPH DATA
              </div>
              <div className="display-flex flex-justify-content-space-between">
                <div
                  style={{
                    transform: "translate(0px,-2px)",
                  }}
                >
                  <CornerLIcon width={8} height={8} />
                </div>
                <div
                  style={{
                    transform: "rotate(90deg) translate(-1px,-3px)",
                  }}
                >
                  <CornerLIcon width={8} height={8} />
                </div>
              </div>
              <div
                style={{
                  minHeight: "300px",
                }}
                className="flex-align-items-center flex-justify-content-center flex-align-items-center padding-larger position-relative"
              >
                <div className="overlay font-size-medium">COMING SOON...</div>
                <BarChart
                  data={barChartData}
                  options={barChartOptions}
                  plugins={[stripeBarChartPlugin]}
                />
              </div>
              <div className="display-flex flex-justify-content-space-between">
                <div
                  style={{
                    transform: "rotate(270deg)",
                  }}
                >
                  <CornerLIcon width={8} height={8} />
                </div>
                <div
                  style={{
                    transform: "rotate(180deg) translate(-3px,-1.5px)",
                  }}
                >
                  <CornerLIcon width={8} height={8} />
                </div>
              </div>
            </div>
            <div
              style={{
                width: "33%",
              }}
              className="display-flex overflow-scroll"
            >
              <div className="inherit-parent-width display-flex flex-direction-column">
                <div className="font-size-medium padding-bottom-large text-align-center">
                  MANPOWER
                </div>
                <div
                  style={{
                    transform: "translate(0px,-2.3px)",
                  }}
                  className="display-flex flex-justify-content-space-between"
                >
                  <div>
                    <CornerLIcon width={8} height={8} />
                  </div>
                  <div
                    style={{
                      transform: "rotate(90deg) translate(0px,-1px)",
                    }}
                  >
                    <CornerLIcon width={8} height={8} />
                  </div>
                </div>
                <div
                  style={{
                    minHeight: "300px",
                  }}
                  className="position-relative flex-direction-column padding-larger flex-align-items-center flex-justify-content-space-between "
                >
                  {!props.showOverlay && (
                    <div className="overlay font-size-medium">
                      COMING SOON...
                    </div>
                  )}

                  <div className="padding-bottom-large padding-top-larger margin-top-larger">
                    <ProgressChart title="POSITIVE PROCEDURES" />
                  </div>

                  <div className="padding-bottom-large">
                    <ProgressChart title="POSITIVE PROCEDURES" />
                  </div>
                </div>
                <div className="display-flex flex-justify-content-space-between">
                  <div
                    style={{
                      transform: "rotate(270deg) translate(-0.1px,0px)",
                    }}
                  >
                    <CornerLIcon width={8} height={8} />
                  </div>
                  <div
                    style={{
                      transform: "rotate(180deg) translate(0px,1px)",
                    }}
                  >
                    <CornerLIcon width={8} height={8} />
                  </div>
                </div>
              </div>
            </div>
            <div
              className="inherit-parent-height"
              style={{
                width: "33%",
              }}
              onMouseLeave={() => {
                let tooltipEl = document.getElementById("chartjs-tooltip");
                if (tooltipEl) {
                  tooltipEl.style.opacity = 0;
                }
              }}
            >
              <div className="font-size-medium text-align-center padding-bottom-large">
                STATUS DATA
              </div>
              <div className="display-flex inherit-parent-height flex-align-items-center flex-justify-content-center">
                <div className="inherit-parent-height inherit-parent-width">
                  <div
                    style={{
                      transform: "translate(0px,-3.7px)",
                    }}
                    className="display-flex flex-justify-content-space-between"
                  >
                    <div
                      style={{
                        transform: "translate(-2px,0px)",
                      }}
                    >
                      <CornerLIcon width={8} height={8} />
                    </div>
                    <div
                      style={{
                        transform: "rotate(90deg)",
                      }}
                    >
                      <CornerLIcon width={8} height={8} />
                    </div>
                  </div>
                  <div
                    style={{
                      minHeight: "300px",
                    }}
                    className="padding-larger flex-justify-content-center flex-align-items-center inherit-parent-height"
                  >
                    <DoughnutChart
                      data={doughnutChartData}
                      options={doughnutChartOptions}
                      plugins={[stripeDoughnutChartPlugin]}
                    />
                  </div>

                  <div className="display-flex flex-justify-content-space-between">
                    <div
                      style={{
                        transform: "rotate(270deg) translate(2.3px,-3px)",
                      }}
                    >
                      <CornerLIcon width={8} height={8} />
                    </div>
                    <div
                      style={{
                        transform: "rotate(180deg) translate(2.5px,0px)",
                      }}
                    >
                      <CornerLIcon width={8} height={8} />
                    </div>
                  </div>
                </div>
                {/* <div style={{ padding: "8px" }} />
                <div className="inherit-parent-height">
                  <DoughnutChart
                    data={doughnutChartData}
                    options={doughnutChartOptions}
                    plugins={[stripeDoughnutChartPlugin]}
                  />
                  <div className="font-size-medium text-align-center padding-top-large">
                    POSITIVE
                  </div>
                </div>
                <div style={{ padding: "8px" }} />
                <div className="inherit-parent-height">
                  <DoughnutChart
                    data={doughnutChartData}
                    options={doughnutChartOptions}
                    plugins={[stripeDoughnutChartPlugin]}
                  />
                  <div className="font-size-medium text-align-center padding-top-large">
                    POSITIVE
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          minWidth: "700px",
          width: "80%",
          position: "absolute",
          bottom: "60px",
        }}
      >
        <div className="position-relative inherit-parent-width">
          <div className="position-absolute inherit-parent-width display-flex flex-justify-content-space-between">
            <div
              style={{
                height: "44px",
              }}
              className="padding-left-large font-size-medium display-flex  flex-align-items-center"
            >
              {props.falseData || `DISPLAYING ${props.hits?.length} RECORDS`}
            </div>
            <div
              style={{
                height: "44px",
              }}
              onClick={props.summaryClick}
              className="padding-right-large font-size-medium display-flex flex-align-items-center cursor-pointer"
            >
              <div className="padding-right-default">
                <TableIcon />
              </div>
              <div>TABLE &nbsp; &nbsp; &nbsp; &nbsp; </div>
            </div>
          </div>

          <img
            style={{
              minHeight: "44px",
              height: "auto",
              width: "100%",
              objectFit: "fill",
            }}
            src={footerImage}
            alt="footerImage"
          />
        </div>
      </div>
    </div>
  );
}

const ProgressChart = (props) => {
  return (
    <div>
      <div className="padding-bottom-large font-size-medium">{props.title}</div>
      <div className=" display-flex">
        <div className="padding-bottom-large margin-right-large">
          <CornerLIcon width={8} height={8} />
        </div>
        {Array.from({ length: 60 }, (_, index) => (
          <div
            key={index}
            style={{
              height: "16px",
              transform: "rotate(45deg)",
              padding: "1px",
            }}
            className={`margin-right-small margin-top-large margin-bottom-large animated-progress ${
              index <= (40 / 100) * 60
                ? "background-color-light-blue"
                : "background-white"
            }`}
          />
        ))}
        <div
          className="margin-left-large"
          style={{
            transform: "rotate(180deg)",
          }}
        >
          <CornerLIcon width={8} height={8} />
        </div>
      </div>
    </div>
  );
};
