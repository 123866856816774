import store from "../store";
import { actionTypes } from "./saga";

/**
 * @module LocationsSaga
 */

/**
 *
 * @param {object} data
 */
export function putLocationsData(data) {
  store.dispatch({
    type: actionTypes.ADD_LOCATIONS_DATA,
    payload: {
      data: data,
    },
  });
}

/**
 *
 * @param {object} data
 */
export function getComplaintSummary(data) {
  store.dispatch({
    type: actionTypes.GET_COMPLAINT_SUMMARY_DATA,
    payload: {
      data: data,
    },
  });
}
