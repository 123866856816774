import { all, put, takeEvery } from "@redux-saga/core/effects";
import { setErrorStatus } from "../status/action";
import {
  getCaptureSummaryPercentage,
  getComplaintsCount,
  getCompletedProcedureCount,
  getLastManPowerCaptured,
  getStatusCount,
  getValidStatusByCount,
} from "../../Utils/constants";
import { getComplaintSummary } from "../../Services/database";
import store from "../store";

export const actionTypes = {
  ADD_LOCATIONS_DATA: "ADD_LOCATIONS_DATA",
  GET_COMPLAINT_SUMMARY_DATA: "GET_COMPLAINT_SUMMARY_DATA",
};
function* addProceduresDataWorker(action) {
  try {
    yield setLocationsLoading(true);

    const updatedData = {};

    for (const [key, item] of Object.entries(action.payload.data)) {
      const selectedCapturedSummary = store.getState().employees
        .captureSummary?.[item.documentId];

      const totalManPowerCapacity =
        item.documentId && selectedCapturedSummary
          ? getLastManPowerCaptured(selectedCapturedSummary)
          : 0;
      const totalManPower =
        item.documentId && selectedCapturedSummary
          ? getCaptureSummaryPercentage(selectedCapturedSummary)
          : 0;
      const totalOpenCompliant = item.complaintCount
        ? getComplaintsCount(item, "open")
        : 0;
      const totalClosedCompliant = item.complaintCount
        ? getComplaintsCount(item, "closed")
        : 0;
      const totalUnderReviewCompliant = item.complaintCount
        ? getComplaintsCount(item, "underReview")
        : 0;
      const totalPositiveProcedure = item.procedureCount
        ? getCompletedProcedureCount(item)
        : 0;
      const totalNegativeProcedure = item.procedureCount
        ? getCompletedProcedureCount(item)
        : 0;
      const totalCompletedProcedure = item.procedureCount
        ? getCompletedProcedureCount(item)
        : 0;
      const status = getValidStatusByCount(getStatusCount(item));

      updatedData[key] = {
        ...item,
        totalManPowerCapacity,
        totalManPower,
        totalOpenCompliant,
        totalClosedCompliant,
        totalUnderReviewCompliant,
        totalPositiveProcedure,
        totalNegativeProcedure,
        totalCompletedProcedure,
        status,
      };
    }

    yield put({
      type: "SET_LOCATIONS_DATA",
      payload: { data: updatedData },
    });
    yield setLocationsLoading(false);
  } catch (error) {
    yield setLocationsLoading(false);
    setErrorStatus(error);
  }
}

function* getComplaintSummaryWorker() {
  try {
    yield setLocationsLoading(true);
    const summaryData = yield getComplaintSummary();
    yield put({
      type: "SET_COMPLAINT_SUMMARY_DATA",
      payload: { data: summaryData },
    });
    yield setLocationsLoading(false);
  } catch (error) {
    yield setLocationsLoading(false);
    setErrorStatus(error);
  }
}

export default function* locationsWatcher() {
  yield all([
    takeEvery("ADD_LOCATIONS_DATA", addProceduresDataWorker),
    takeEvery("GET_COMPLAINT_SUMMARY_DATA", getComplaintSummaryWorker),
  ]);
}

function* setLocationsLoading(bool) {
  yield put({
    type: "SET_LOCATIONS_LOADING",
    payload: {
      loading: bool,
    },
  });
}
