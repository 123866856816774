import { all, put, takeEvery } from "@redux-saga/core/effects";
import { setErrorStatus, setSuccessStatus } from "../status/action";
import { uploadCompliantProof } from "../../Services/storage";
import { isValidArray, isValidObject } from "../../Services/validators";
import {
  getAllComplaints,
  takeoverCompliant,
  updateCompliant,
  updateVerifyCompliant,
} from "../../Services/database";
import store from "../store";
import { verifyCompliant } from "./action";
import { bucketNames } from "../../Utils/constants";

export const actionTypes = {
  PUT_COMPLAINTS_DATA: "PUT_COMPLAINTS_DATA",
  GET_ALL_COMPLAINTS_COUNTS: "GET_ALL_COMPLAINTS_COUNTS",
  PUT_COMPLAINTS_EXPORT_LOADING: "PUT_COMPLAINTS_EXPORT_LOADING",
  VERIFY_COMPLAINT: "VERIFY_COMPLAINT",
  GET_COMPLAINTS_ASSETS: "GET_COMPLAINTS_ASSETS",
  GET_TOTAL_VALUES: "GET_TOTAL_VALUES",
  FIX_COMPLAINTS: "FIX_COMPLAINTS",
  COMPLAINT_TAKEOVER: "COMPLAINT_TAKEOVER",
};

function* getAllComplaintsCountWorker() {
  try {
    yield setSummaryLoading(true);
    const complaints = yield getAllComplaints();
    const dateRange = store.getState().table?.complaints?.filter?.dateRange;
    const typeCounts = complaints.reduce((acc, item) => {
      const from = +new Date(dateRange?.from).setHours(0, 0, 0, 0);
      const to = +new Date(dateRange?.to).setHours(0, 0, 0, 0) + 86400000;

      if (dateRange.from && dateRange.to) {
        if (item.createdAt >= from && item.createdAt <= to) {
          const type =
            item.issuedBy?.type === "procedure" || !item.issuedBy?.type
              ? "internal"
              : item.issuedBy?.type;
          if (acc[type]) {
            acc[type]++;
          } else {
            acc[type] = 1;
          }
        }
      } else {
        const type =
          item.issuedBy?.type === "procedure" || !item.issuedBy?.type
            ? "internal"
            : item.issuedBy?.type;
        if (acc[type]) {
          acc[type]++;
        } else {
          acc[type] = 1;
        }
      }
      return acc;
    }, {});

    yield put({
      type: "SET_COMPLAINT_COUNT_DATA",
      payload: { data: typeCounts },
    });

    yield setSummaryLoading(false);
  } catch (error) {
    yield setSummaryLoading(false);
    setErrorStatus(error);
    console.error(error, "getAllComplaintsCountWorker");
  }
}

function* putComplaintsWorker(action) {
  try {
    if (action.payload.type === "SET") {
      yield setComplaintsLoading(true);
      yield put({
        type: "SET_COMPLAINTS_DATA",
        payload: {
          data: action.payload.data,
        },
      });
    }

    if (action.payload.type === "ADD") {
      yield setComplaintsPaginationLoading(true);
      yield put({
        type: "ADD_COMPLAINTS_DATA",
        payload: {
          data: action.payload.data,
        },
      });
    }

    yield setComplaintsLoading(false);
    yield setComplaintsPaginationLoading(false);
  } catch (error) {
    yield setComplaintsLoading(false);
    yield setComplaintsPaginationLoading(false);
    setErrorStatus(error);
  }
}

function* verifyComplaintWorker(action) {
  try {
    yield setComplaintsLoading(true);
    const data = {
      status: {
        currentStatus: action.payload.type === "close" ? "CLOSED" : "OPEN",
        updatedAt: +new Date(),
      },
      closedBy: {
        uid: store.getState().auth.data.uid,
        email: store.getState().auth.data.email,
      },
    };

    yield updateVerifyCompliant(action.payload.compliantData.documentId, data);
    yield setSuccessStatus(
      `Complaint ${
        action.payload.type === "close" ? "closed" : "opened"
      } successfully`
    );

    yield setComplaintsLoading(false);
  } catch (error) {
    console.error("verifyComplaintWorker", error);
    yield setComplaintsLoading(false);
    setErrorStatus(error);
  }
}

// function* getComplaintsAssetsWorker(action) {
//   try {
//     yield setComplaintsAssetsLoading(true);
//     const data = store.getState().complaints?.data?.[
//       action.payload.selectedComplaint
//     ];

//     let complaintFileSrc = {};
//     let complaintImages = [];

//     if (isValidArray(data.assets)) {
//       for (let j = 0; j < data.assets.length; j++) {
//         const file = yield getFileUrl(data.assets[j]);
//         if (file.type === "image") {
//           complaintImages.push(file.url);
//         } else if (file.type === "audio") {
//           complaintFileSrc = { ...complaintFileSrc, audio: file.url };
//         }
//       }
//     }

//     let closureFileSrc = {};
//     let closureImages = [];
//     if (isValidArray(data.closure?.proof)) {
//       for (let j = 0; j < data.closure?.proof.length; j++) {
//         const file = yield getFileUrl(data.closure?.proof[j]);
//         if (file.type === "image") {
//           closureImages.push(file.url);
//         } else if (file.type === "audio") {
//           closureFileSrc = { ...closureFileSrc, audio: file.url };
//         }
//       }
//     }

//     const newData = {
//       ...store.getState().complaints.data,

//       [action.payload.selectedComplaint]: {
//         ...data,
//         fileSrc: {
//           ...complaintFileSrc,
//           ...(isValidArray(complaintImages) ? { images: complaintImages } : {}),
//         },
//         closure: {
//           ...data.closure,
//           fileSrc: {
//             ...closureFileSrc,
//             ...(isValidArray(closureImages) ? { images: closureImages } : {}),
//           },
//         },
//       },
//     };
//     yield setComplaintsAssetsLoading(false);

//     // yield put({
//     //   type: "ADD_COMPLAINTS_DATA",
//     //   payload: { ...newData },
//     // });
//   } catch (error) {
//     yield setComplaintsAssetsLoading(false);

//     setErrorStatus(error);
//   }
// }

function* getTotalValuesWorker(action) {
  try {
    yield setComplaintsLoading(true);

    const daysInMonth = (month, year) => {
      return new Date(year, month, 0).getDate();
    };

    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1;
    const daysInCurrMonth = daysInMonth(currentMonth, currentYear);

    let positive = new Array(daysInCurrMonth).fill(0);
    let negative = new Array(daysInCurrMonth).fill(0);
    if (isValidArray(action.payload.data)) {
      action.payload.data.forEach((element) => {
        if (
          isValidObject(element.procedureCount) &&
          element.procedureCount[currentYear]?.[currentMonth]
        ) {
          const value = Object.entries(
            element.procedureCount[currentYear]?.[currentMonth]
          );
          value.forEach(([day, values]) => {
            positive[parseInt(day)] += values.positive ? values.positive : 0;
            negative[parseInt(day)] += values.negative ? values.negative : 0;
          });
        }
      });
    }

    yield put({
      type: "SET_TOTAL_PROCEDURE_COUNT",
      payload: {
        positive: positive,
        negative: negative,
      },
    });

    yield setComplaintsLoading(false);
  } catch (error) {
    yield setComplaintsLoading(false);
    setErrorStatus(error);
  }
}

function* fixComplaintsWorker(action) {
  try {
    yield setComplaintsLoading(true);

    const { proof, complaintId, locationId, employeeId } = action.payload;
    let imageFileUrls = [];
    let audioFileUrls = [];

    if (isValidArray(proof.image)) {
      const imageUploadResults = yield* uploadFiles(
        proof.image,
        complaintId,
        locationId,
        "closure"
      );

      imageFileUrls = imageUploadResults.map(
        (result) =>
          `${bucketNames.defaultBucket}/${locationId}/complaints/${complaintId}/closure/${result}`
      );
    }

    if (proof.audio) {
      const audioUploadResults = yield* uploadFiles(
        [proof.audio],
        complaintId,
        locationId,
        "closure"
      );
      audioFileUrls = audioUploadResults.map(
        (result) =>
          `${bucketNames.defaultBucket}/${locationId}/complaints/${complaintId}/closure/${result}`
      );
    }

    const filesUrls = [...imageFileUrls, ...audioFileUrls];

    yield updateCompliant(complaintId, employeeId, filesUrls);

    verifyCompliant(store.getState().complaints?.data?.[complaintId], "close");

    // getComplaintsData(locationId);

    yield setComplaintsLoading(false);
  } catch (error) {
    console.error("fixComplaintsWorker", error);
    setErrorStatus(error);
    yield setComplaintsLoading(false);
  }
}

function* complaintTakeoverWorker(action) {
  try {
    yield setComplaintsLoading(true);
    yield takeoverCompliant(
      action.payload.complaintId,
      action.payload.employeeData
    );

    setSuccessStatus("Compliant Taken over successfully");

    yield setComplaintsLoading(false);
  } catch (error) {
    console.error("complaintTakeoverWorker", error);
    setErrorStatus(error);
    yield setComplaintsLoading(false);
  }
}
function* setComplaintExportLoadingWorker(action) {
  yield put({
    type: "SET_COMPLAINTS_EXPORT_LOADING",
    payload: {
      loading: action.payload.loading,
    },
  });
}

export default function* complaintsWatcher() {
  yield all([
    takeEvery("PUT_COMPLAINTS_DATA", putComplaintsWorker),
    takeEvery("PUT_COMPLAINTS_EXPORT_LOADING", setComplaintExportLoadingWorker),
    takeEvery("GET_ALL_COMPLAINTS_COUNTS", getAllComplaintsCountWorker),
    takeEvery("VERIFY_COMPLAINT", verifyComplaintWorker),
    // takeEvery("GET_COMPLAINTS_ASSETS", getComplaintsAssetsWorker),
    takeEvery("GET_TOTAL_VALUES", getTotalValuesWorker),
    takeEvery("FIX_COMPLAINTS", fixComplaintsWorker),
    takeEvery("COMPLAINT_TAKEOVER", complaintTakeoverWorker),
  ]);
}

function* setComplaintsLoading(bool) {
  yield put({
    type: "SET_COMPLAINTS_LOADING",
    payload: {
      loading: bool,
    },
  });
}
function* setSummaryLoading(bool) {
  yield put({
    type: "SET_SUMMARY_LOADING",
    payload: {
      loading: bool,
    },
  });
}

function* setComplaintsPaginationLoading(bool) {
  yield put({
    type: "SET_COMPLAINTS_PAGINATION_LOADING",
    payload: {
      loading: bool,
    },
  });
}

// function* setComplaintsAssetsLoading(bool) {
//   yield put({
//     type: "SET_COMPLAINTS_ASSETS_LOADING",
//     payload: {
//       assetsLoading: bool,
//     },
//   });
// }

function* uploadFiles(files, complaintId, locationId, folder) {
  const uploadPromises = files.map((file) =>
    uploadCompliantProof(file, complaintId, locationId, folder)
  );
  return yield Promise.all(uploadPromises);
}
