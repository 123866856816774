import React from "react";
import "./Button.css";
import "../../index.css";
import { rippleEffect } from "../../Utils/constants";

function Button(props) {
  const getClassName = () => {
    if (props.disabled === true) {
      return " cursor-no-drop";
    } else if (props.variant === "secondary") {
      return " cursor-pointer secondary-button";
    } else {
      return " cursor-pointer primary-button";
    }
  };

  const getBorderColor = () => {
    if (props.borderColor) {
      return props.borderColor;
    } else if (props.disabled){
      return "#404040";
    } else if (props.variant === "secondary") {
      return "#A9A9A9";
    } else {
      return "white";
    }
  };

  const getTextColor = () => {
    if (props.textColor) {
      return props.textColor;
    } else if (props.disabled) {
      return "#404040";
    } else {
      return "white";
    }
  };

  return (
    <React.Fragment key={props.buttonKey}>
      <button
        style={{
          height: "44px",
          border: "2px solid",
          borderColor: getBorderColor(),
          background: "transparent",
          color: getTextColor(),
        }}
        className={` ${
          props.className
        } ${getClassName()} font-family-aldrich-regular inherit-parent-width flex-align-items-center flex-justify-content-center     `}
        onClick={(event) => {
          rippleEffect(event);
          if (props.loading === true) return;
          if (typeof props.onClick === "function") {
            props.onClick(event);
          }
        }}
        disabled={props.disabled || props.loading}
        type={props.type}
        data-cy={props["data-cy"] ? props["data-cy"] : "button-component"}
      >
        <div className=" inherit-parent-width display-flex flex-justify-content-space-between flex-align-items-center ">
          <div
            className="triangle-bottom-left-corner  "
            style={{
              position: "relative",
              top: "17px",
              right: "2px",
              borderRightColor: getBorderColor(),
            }}
          />
          {props.loading !== true ? (
            <div style={{ zIndex: "100" }}>{props.text}</div>
          ) : (
            <div className="flex-place-children-page-center padding-vertical-small margin-top-smaller">
              <div
                className={`dot-pulse`}
              />
            </div>
          )}
          <div
            className="triangle-bottom-right-corner "
            style={{
              position: "relative",
              bottom: "17px",
              left: "3px",
              borderRightColor: getBorderColor(),
            }}
          />
        </div>
      </button>
    </React.Fragment>
  );
}

export default Button;
