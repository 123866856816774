import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import statusReducer from "./status/reducer";
import profileReducer from "./profile/reducer";
import authReducer from "./authentication/reducer";
import complaintsReducer from "./complaints/reducer";
import proceduresReducer from "./procedures/reducer";
import employeesReducer from "./employees/reducer";
import sensorsReducer from "./sensors/reducer";
import algoliaReducer from "./algolia/reducer";
import locationsReducer from "./locations/reducer";
import surveillanceReducer from "./surveillance/reducer";
import tracksReducer from "./track/reducer";
import tableReducer from "./table/reducer";

const algoliaPersistConfig = {
  key: "algolia",
  storage: storage,
  blacklist: ["data"],
};

const authPersistConfig = {
  key: "auth",
  storage: storage,
  blacklist: ["loading", "credentials"],
};

const profilePersistConfig = {
  key: "profile",
  storage: storage,
  blacklist: ["loading"],
};

const statusPersistConfig = {
  key: "status",
  storage: storage,
  blacklist: ["code", "message"],
};

const complaintsPersistConfig = {
  key: "complaints",
  storage: storage,
  blacklist: ["loading"],
};

const tracksPersistConfig = {
  key: "tracks",
  storage: storage,
  blacklist: ["loading", "data"],
};

const proceduresPersistConfig = {
  key: "procedures",
  storage: storage,
  blacklist: ["loading"],
};

const employeesPersistConfig = {
  key: "employees",
  storage: storage,
  blacklist: ["loading"],
};

const locationsPersistConfig = {
  key: "locations",
  storage: storage,
  blacklist: ["loading"],
};

const sensorsPersistConfig = {
  key: "sensors",
  storage: storage,
  blacklist: ["loading"],
};

const surveillancePersistConfig = {
  key: "surveillance",
  storage: storage,
  blacklist: ["loading"],
};

const tablePersistConfig = {
  key: "table",
  storage: storage,
  blacklist: ["loading"],
};

const rootPersistConfig = {
  key: "root",
  storage: storage,
  blacklist: ["auth", "profile", "status"],
};

//root reducer
const rootReducer = combineReducers({
  algolia: persistReducer(algoliaPersistConfig, algoliaReducer),
  auth: persistReducer(authPersistConfig, authReducer),
  complaints: persistReducer(complaintsPersistConfig, complaintsReducer),
  tracks: persistReducer(tracksPersistConfig, tracksReducer),
  locations: persistReducer(locationsPersistConfig, locationsReducer),
  profile: persistReducer(profilePersistConfig, profileReducer),
  status: persistReducer(statusPersistConfig, statusReducer),
  procedures: persistReducer(proceduresPersistConfig, proceduresReducer),
  employees: persistReducer(employeesPersistConfig, employeesReducer),
  sensors: persistReducer(sensorsPersistConfig, sensorsReducer),
  surveillance: persistReducer(surveillancePersistConfig, surveillanceReducer),
  table: persistReducer(tablePersistConfig, tableReducer),
});

export const persistedReducer = persistReducer(rootPersistConfig, rootReducer);
