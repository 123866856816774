import React from "react";
import { Doughnut } from "react-chartjs-2";

export function DoughnutChart(props) {
  return (
    <div className="inherit-parent-height inherit-parent-width position-relative">
      <Doughnut
        data={props.data}
        options={props.options}
        plugins={props.plugins}
        style={props.style}
        className="inherit-parent-width height-width"
      />
    </div>
  );
}
