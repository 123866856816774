import { all, put, takeEvery } from "@redux-saga/core/effects";
import { setErrorStatus, setSuccessStatus } from "../status/action";
import { getFileUrl } from "../../Services/storage";
import { getProcedureHistory } from "../../Services/database";
import { isValidArray } from "../../Services/validators";
import {
  createProcedurePreset,
  editProcedurePreset,
} from "../../Services/database";
import store from "../store";

export const actionTypes = {
  CREATE_PROCEDURE_PRESET: "CREATE_PROCEDURE_PRESET",
  EDIT_PROCEDURE_PRESET: "EDIT_PROCEDURE_PRESET",
  ADD_PROCEDURES_DATA: "ADD_PROCEDURES_DATA",
  GET_PROCEDURES_HISTORY: "GET_PROCEDURES_HISTORY",
  GET_PROCEDURES_ASSETS: "GET_PROCEDURES_ASSETS",
  PUT_PROCEDURES_HISTORY: "PUT_PROCEDURES_HISTORY",
};

function* addProceduresDataWorker(action) {
  try {
    yield setProceduresLoading(true);
    yield put({
      type: "SET_PROCEDURES_DATA",
      payload: { data: action.payload.data },
    });
    yield setProceduresLoading(false);
  } catch (error) {
    yield setProceduresLoading(false);
    setErrorStatus(error);
  }
}

function* getProceduresHistoryDataWorker(action) {
  try {
    yield setProceduresLoading(true);

    const procedureHistory = yield getProcedureHistory(
      action.payload.locationId
    );

    const data = procedureHistory.reduce(
      (acc, procedure) => {
        acc[action.payload.locationId][procedure.documentId] = procedure;
        return acc;
      },
      { [action.payload.locationId]: {} }
    );

    yield put({
      type: "SET_PROCEDURES_HISTORY_DATA",
      payload: data,
    });

    yield setProceduresLoading(false);
  } catch (error) {
    yield setProceduresLoading(false);
    setErrorStatus(error);
  }
}

function* getProceduresAssetsWorker(action) {
  try {
    yield setProceduresAssetsLoading(true);
    const storeData = store.getState().procedures?.history?.[
      action.payload.locationId
    ]?.[action.payload.selectedProcedure];

    let i = action.payload.index;

    if (typeof i === "number") {
      const assets = storeData.latestUpdate[i].proof;

      let images = [];
      let fileSrc = {};
      if (isValidArray(assets)) {
        for (let k = 0; k < assets.length; k++) {
          const file = yield getFileUrl(assets[k]);
          if (file.type === "image") {
            images.push(file.url);
          } else if (file.type === "audio") {
            fileSrc = { ...fileSrc, audio: file.url };
          }
        }

        storeData.latestUpdate[i] = {
          ...storeData.latestUpdate[i],
          fileSrc: {
            ...fileSrc,
            ...(isValidArray(images) ? { images: images } : {}),
          },
        };
      } else {
        storeData.latestUpdate[i] = {
          ...storeData.latestUpdate[i],
          fileSrc: {},
        };
      }
      const procedureData = store.getState().procedures.history;
      const newData = {
        ...procedureData,
        [action.payload.locationId]: {
          ...procedureData?.[action.payload.locationId],
          [action.payload.selectedProcedure]: {
            ...storeData,
          },
        },
      };

      yield put({
        type: "SET_PROCEDURES_HISTORY_DATA",
        payload: {
          ...newData,
        },
      });
    }

    yield setProceduresAssetsLoading(false);
  } catch (error) {
    yield setProceduresAssetsLoading(false);
    setErrorStatus(error);
  }
}

function* createProcedurePresetWorker(action) {
  try {
    yield setProceduresLoading(true);
    yield createProcedurePreset(action.payload.data);
    yield setSuccessStatus("Procedure preset created successfully");
    yield setProceduresLoading(false);
  } catch (error) {
    yield setProceduresLoading(false);
    setErrorStatus(error);
  }
}

function* editProcedurePresetWorker(action) {
  try {
    yield setProceduresLoading(true);
    const data = action.payload.data;
    const documentId = data.documentId;
    delete data.documentId;
    yield editProcedurePreset(documentId, data);
    yield setSuccessStatus("Procedure preset edited successfully");
    yield setProceduresLoading(false);
  } catch (error) {
    yield setProceduresLoading(false);
    setErrorStatus(error);
  }
}

function* putProceduresHistoryWorker(action) {
  try {
    yield put({
      type: "SET_PROCEDURES_HISTORY_DATA",
      payload: { procedureHistory: action.payload.procedureHistory },
    });
  } catch (error) {
    yield setProceduresLoading(false);
    setErrorStatus(error);
  }
}

export default function* proceduresWatcher() {
  yield all([
    takeEvery("ADD_PROCEDURES_DATA", addProceduresDataWorker),
    takeEvery("CREATE_PROCEDURE_PRESET", createProcedurePresetWorker),
    takeEvery("EDIT_PROCEDURE_PRESET", editProcedurePresetWorker),
    takeEvery("GET_PROCEDURES_HISTORY", getProceduresHistoryDataWorker),
    takeEvery("GET_PROCEDURES_ASSETS", getProceduresAssetsWorker),
    takeEvery("PUT_PROCEDURES_HISTORY", putProceduresHistoryWorker),
  ]);
}

function* setProceduresLoading(bool) {
  yield put({
    type: "SET_PROCEDURES_LOADING",
    payload: {
      loading: bool,
    },
  });
}
function* setProceduresAssetsLoading(bool) {
  yield put({
    type: "SET_PROCEDURES_ASSETS_LOADING",
    payload: {
      assetsLoading: bool,
    },
  });
}
