import React, { useMemo, useState } from "react";
import SearchFallBackIcon from "../../Assets/SearchFallBackIcon.svg";
import { isValidArray, isValidObject } from "../../Services/validators";
import {
  alignPhoneNumber,
  capitalizeFirstLetter,
  dateAndTimeConverter,
  isDigit,
} from "../../Utils/constants";
import Table from "../Table/Table";

export default function EmployeeTable(props) {
  const [sortConfig, setSortConfig] = useState({ title: "", ascending: true });

  const sortedEmployees = useMemo(() => {
    if (!isValidObject(props.employees.data)) {
      return [];
    }

    if (!sortConfig?.title || !isValidObject(props.employees.data)) {
      return props.employees.data
        ? Object.values(props.employees.data).sort(
            (a, b) =>
              b.captureStatus?.lastCapturedAt - a.captureStatus?.lastCapturedAt
          )
        : [];
    }
    return Object.values(props.employees.data).sort((a, b) => {
      let nameA, nameB;

      switch (sortConfig?.title) {
        case "NAME":
          nameA = a.name?.toUpperCase() || "N/A";
          nameB = b.name?.toUpperCase() || "N/A";
          break;
        case "PHONE NUMBER":
          nameA = a.phoneNumber || "N/A";
          nameB = b.phoneNumber || "N/A";
          break;
        case "CAPTURED AT":
          nameA = a.captureStatus?.lastCapturedAt || 0;
          nameB = b.captureStatus?.lastCapturedAt || 0;
          break;

        default:
          break;
      }

      if (isDigit(nameA) && isDigit(nameB)) {
        nameA = parseInt(nameA);
        nameB = parseInt(nameB);
      }
      if (nameA < nameB) {
        return sortConfig.ascending ? -1 : 1;
      }
      if (nameA > nameB) {
        return sortConfig.ascending ? 1 : -1;
      }
      return 0;
    });
    // eslint-disable-next-line
  }, [props.employees.data, sortConfig]);

  const tableHeadings = [
    { title: "NAME", width: "220px" },
    { title: "PHONE NUMBER", width: "180px" },
    {
      title: "CAPTURED AT",
      width: "180px",
      alignItemCenter: true,
    },
  ];

  const handleSort = (title) => {
    setSortConfig((prevConfig) => ({
      title,
      ascending: prevConfig.title === title ? !prevConfig.ascending : true,
    }));
  };

  return (
    <div
      className="remaining-body-height-from-footer inherit-parent-width"
      style={{
        minWidth: "700px",
      }}
    >
      {isValidArray(sortedEmployees) && (
        <Table
          showSummary="disabled"
          showCreate={true}
          headers={tableHeadings}
          onScrollReachedEnd={() => {
            // if (props.complaints.paginationLoading === false) {
            // props.getProcedureHistoryNextPage();
            // }
          }}
          summaryClick={props.summaryClick}
          createClick={props.createClick}
          data={sortedEmployees?.map((data) => ({
            documentId: data?.employeeId,
            name: capitalizeFirstLetter(data?.name),
            phoneNumber: alignPhoneNumber(data?.phoneNumber),
            capturedAt: data?.captureStatus?.lastCapturedAt
              ? `${dateAndTimeConverter(
                  data?.captureStatus?.lastCapturedAt,
                  "Time"
                )}, ${dateAndTimeConverter(
                  data?.captureStatus?.lastCapturedAt,
                  "thirdDate"
                )}, PCT : ${data?.captureStatus?.location?.PCTNo}`
              : "N/A",
          }))}
          onClick={(data) => {
            props.setSelectedTableId(data?.documentId);
          }}
          getField={employeesFields}
          sort={sortConfig}
          handleSort={(data) => {
            handleSort(data);
          }}
          theme={props.theme}
        />
      )}

      {!isValidArray(sortedEmployees) && (
        <div
          className="parent-height-sub-42px inherit-parent-width font-size-default display-flex flex-direction-column flex-justify-content-center flex-align-items-center"
          data-cy="no-search-or-filter-fallback"
        >
          <img src={SearchFallBackIcon} alt="no filter result found" />
          <div className="padding-top-larger">NO MATCHING RESULTS</div>
          <div className="padding-top-large">
            There wasn’t any result matching to the search and filter
          </div>
        </div>
      )}
    </div>
  );
}

function employeesFields(data) {
  switch (data) {
    case "NAME":
      return "name";
    case "PHONE NUMBER":
      return "phoneNumber";
    case "CAPTURED AT":
      return "capturedAt";
    default:
      return data?.toLowerCase();
  }
}
