import { defaultFilterData } from "../../Utils/constants";

const tableReducer = (
  state = {
    loading: false,
    selectedTable: "LOCATIONS",
    locations: {
      selectedLocation: null,
      filter: defaultFilterData.locations,
    },
    complaints: {
      selectedComplaint: null,
      filter: defaultFilterData.complaints,
    },
    procedures: {
      selectedProcedure: null,
      filter: defaultFilterData.procedures,
    },
    employees: {
      selectedEmployee: null,
      filter: defaultFilterData.employees,
    },
  },
  action
) => {
  switch (action.type) {
    case "SET_TABLE_LOADING":
      return {
        ...state,
        loading: action.payload.loading,
      };

    case "PUT_SELECTED_TABLE":
      return {
        ...state,
        selectedTable: action.payload.selectedTable,
      };

    case "PUT_SELECTED_LOCATION":
      return {
        ...state,
        locations: { ...state.locations, selectedLocation: action.payload.id },
      };

    case "PUT_SELECTED_COMPLAINT":
      return {
        ...state,
        complaints: {
          ...state.complaints,
          selectedComplaint: action.payload.id,
        },
      };

    case "PUT_SELECTED_PROCEDURE":
      return {
        ...state,
        procedures: {
          ...state.procedures,
          selectedProcedure: action.payload.id,
        },
      };

    case "PUT_SELECTED_EMPLOYEE":
      return {
        ...state,
        employees: {
          ...state.employees,
          selectedEmployee: action.payload.id,
        },
      };

    case "PUT_LOCATIONS_FILTER":
      return {
        ...state,
        locations: {
          ...state.locations,
          filter: action.payload.filter,
        },
      };

    case "PUT_COMPLAINTS_FILTER":
      return {
        ...state,
        complaints: {
          ...state.complaints,
          filter: action.payload.filter,
        },
      };

    case "PUT_PROCEDURES_FILTER":
      return {
        ...state,
        procedures: {
          ...state.procedures,
          filter: action.payload.filter,
        },
      };

    case "RESET":
      return {
        loading: false,
        selectedTable: "LOCATIONS",
        locations: {
          selectedLocation: null,
          filter: defaultFilterData.locations,
        },
        complaints: {
          selectedComplaint: null,
          filter: defaultFilterData.complaints,
        },
        procedures: {
          selectedProcedure: null,
          filter: defaultFilterData.procedures,
        },
        employees: {
          selectedEmployee: null,
          filter: defaultFilterData.employees,
        },
      };

    default:
      return state;
  }
};
export default tableReducer;
