import { api } from "../Utils/constants";
import { setErrorStatus } from "../Redux/status/action";

export async function checkAccountAvailability(phoneNumber) {
  const response = await fetch(
    `${api.baseUrl}/profile/isExists?phoneNumber=${encodeURIComponent(
      phoneNumber
    )}`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );
  const result = await response.json();
  if (result.error) {
    if (result.error.name === "requestError") {
      setErrorStatus({ code: "custom", message: result.error.message });
    } else {
      throw result.error;
    }
  }
  return result;
}

export async function exportFiles(accessToken, data) {
  const response = await fetch(`${api.baseUrl}/admin/getComplaintCount`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-auth-token": `Bearer ${accessToken}`,
    },
    body: JSON.stringify({
      filter: data,
      type: "excel",
    }),
  });

  if (!response.ok) {
    const errorMessage = await response.text();
    console.error(errorMessage);
    throw new Error(`Error while fetching exporting`);
  }

  const result = await response.blob();
  if (result.error) {
    if (result.error.name === "requestError") {
      setErrorStatus({ code: "custom", message: result.error.message });
    } else {
      throw result.error;
    }
  }
  return result;
}
