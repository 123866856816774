const locationsReducer = (
  state = {
    loading: false,
    data: null,
    summaryData: null,
  },
  action
) => {
  switch (action.type) {
    case "SET_LOCATIONS_LOADING":
      return {
        ...state,
        loading: action.payload.loading,
      };

    case "SET_LOCATIONS_DATA":
      return {
        ...state,
        data: action.payload.data,
      };

    case "SET_COMPLAINT_SUMMARY_DATA":
      return {
        ...state,
        summaryData: action.payload.data,
      };

    case "RESET":
      return {
        loading: false,
        data: null,
        summaryData: null,
      };

    default:
      return state;
  }
};
export default locationsReducer;
