import React, { useEffect, useState } from "react";
import { isValidArray, isValidObject } from "../../Services/validators";
import "./Filter.css";
import { CloseIcon, ErrorIcon, SearchIcon } from "../../Assets/assets";
import {
  Regex,
  getComplaintStatusCount,
  getValidStatus,
  transformValue,
} from "../../Utils/constants";
import ToolTip from "../ToolTIp/ToolTip";
import CustomScrollBar from "../CustomScrollBar/CustomScrollBar";

export default function Filter(props) {
  const [inputBoxValue, setInputBoxValue] = useState({
    left: null,
    right: null,
  });

  useEffect(() => {
    if (props.dataCy === "procedure-history-filter") {
      const assignedTo = props.selectedFilter?.assignedTo?.[0];

      if (typeof assignedTo === "string" && assignedTo?.length > 0) {
        if (isValidObject(props.employees.data?.[assignedTo])) {
          setInputBoxValue({
            ...inputBoxValue,
            left: {
              assignedTo: props.employees.data?.[
                assignedTo
              ]?.phoneNumber?.split("+91")?.[1],
            },
          });
        }
      }
    }

    if (props.dataCy === "complaints-history-filter") {
      const issueRaised = props.selectedFilter?.issueRaised?.[0];
      const issueTakenBy = props.selectedFilter?.issueTakenBy?.[0];

      if (typeof issueRaised === "string" && issueRaised?.length > 0) {
        setInputBoxValue({
          ...inputBoxValue,
          left: {
            issueRaised: issueRaised?.split("+91")?.[1],
          },
        });
      }

      if (typeof issueTakenBy === "string" && issueTakenBy?.length > 0) {
        if (issueTakenBy?.includes("@")) {
          setInputBoxValue({
            ...inputBoxValue,
            left: {
              issueTakenBy: issueTakenBy,
            },
          });
        } else {
          setInputBoxValue({
            ...inputBoxValue,
            left: {
              issueTakenBy: issueTakenBy?.split("+91")?.[1],
            },
          });
        }
      }
    }

    // eslint-disable-next-line
  }, [props.selectedFilter]);

  return (
    <div
      style={{
        paddingRight: "64px",
        paddingLeft: "64px",
      }}
      className="inherit-parent-height inherit-parent-width flex-justify-content-center padding-top-large padding-bottom-large filter-background-image"
      data-cy={`${props.dataCy ? props.dataCy : "filter"}-component`}
    >
      <div
        style={{
          width: "95%",
        }}
        className="inherit-parent-height display-flex flex-justify-content-space-between"
      >
        <div
          className="inherit-parent-height width-30-percentage display-flex flex-direction-column position-relative"
          style={{
            height: "calc(100% - 40px)",
          }}
        >
          <CustomScrollBar horizontal="left">
            {props.filterData
              .filter((data) => data.horizontal === "left")
              .map((data, index, filterDataArray) => (
                <div
                  className="inherit-parent-width display-flex flex-direction-column padding-vertical-default"
                  key={`${index}-${data?.title}`}
                >
                  {/* title section */}
                  <TitleSection
                    data={data}
                    horizontal={data.horizontal}
                    vertical={data.vertical}
                  />

                  {/* form/subtext card - for text values (value - is array of string or numbers) */}
                  {data.type === "text" &&
                    isValidArray(data.value) &&
                    data.value.every(
                      (data) =>
                        typeof data === "string" || typeof data === "number"
                    ) && (
                      <ArrayTextViewer
                        data={data}
                        horizontal={data.horizontal}
                        vertical={data.vertical}
                        setFilter={props.setFilter}
                        selectedFilter={props.selectedFilter}
                      />
                    )}

                  {/* form/subtext card - for text values (value - is array of object) */}
                  {data.type === "text" &&
                    isValidArray(data.value) &&
                    data.value.every((subtext) => isValidObject(subtext)) && (
                      <ArrayOfObjectViewer
                        data={data}
                        horizontal={data.horizontal}
                        vertical={data.vertical}
                        setFilter={props.setFilter}
                        selectedFilter={props.selectedFilter}
                      />
                    )}

                  {/* type = input box */}
                  {data.type === "inputBox" && (
                    <InputViewer
                      data={data}
                      horizontal={data.horizontal}
                      vertical={data.vertical}
                      setFilter={props.setFilter}
                      selectedFilter={props.selectedFilter}
                      setInputBoxValue={setInputBoxValue}
                      inputBoxValue={inputBoxValue}
                      employees={props.employees}
                    />
                  )}

                  {/* type = dateRangeForm */}
                  {data.type === "dateRangeForm" && (
                    <DateRangeViewer
                      data={data}
                      horizontal={data.horizontal}
                      vertical={data.vertical}
                      setFilter={props.setFilter}
                      selectedFilter={props.selectedFilter}
                      setInputBoxValue={setInputBoxValue}
                      inputBoxValue={inputBoxValue}
                    />
                  )}

                  {/* border of the section */}
                  <BorderSection
                    horizontal={data.horizontal}
                    vertical={data.vertical}
                    show={index !== filterDataArray.length - 1}
                  />
                </div>
              ))}
          </CustomScrollBar>
        </div>

        <div
          className="inherit-parent-height width-30-percentage display-flex flex-direction-column text-align-right position-relative"
          style={{
            height: "calc(100% - 40px)",
          }}
        >
          <CustomScrollBar horizontal="right">
            {props.filterData
              .filter((data) => data.horizontal === "right")
              .map((data, index) => (
                <div
                  className="inherit-parent-width display-flex flex-direction-column padding-vertical-default"
                  key={`${index}-${data?.title}`}
                >
                  {/* title section */}
                  <TitleSection
                    data={data}
                    horizontal={data.horizontal}
                    vertical={data.vertical}
                  />

                  {data.type === "text" &&
                    isValidArray(data.value) &&
                    data.value.every(
                      (category) =>
                        typeof category === "string" ||
                        typeof category === "number"
                    ) && (
                      <ArrayTextViewer
                        data={data}
                        horizontal={data.horizontal}
                        vertical={data.vertical}
                        setFilter={props.setFilter}
                        selectedFilter={props.selectedFilter}
                      />
                    )}

                  {/* form/subtext card - for text values (value - is array of object) */}
                  {data.type === "text" &&
                    isValidArray(data?.value) &&
                    data.value.every((subtext) => isValidObject(subtext)) && (
                      <ArrayOfObjectViewer
                        data={data}
                        horizontal={data.horizontal}
                        vertical={data.vertical}
                        setFilter={props.setFilter}
                        selectedFilter={props.selectedFilter}
                      />
                    )}

                  {/* type = input box */}
                  {data.type === "inputBox" && (
                    <InputViewer
                      data={data}
                      horizontal={data.horizontal}
                      vertical={data.vertical}
                      setFilter={props.setFilter}
                      selectedFilter={props.selectedFilter}
                      setInputBoxValue={setInputBoxValue}
                      inputBoxValue={inputBoxValue}
                      employees={props.employees}
                    />
                  )}

                  {/* type = dateRangeForm */}
                  {data.type === "dateRangeForm" && (
                    <DateRangeViewer
                      data={data}
                      horizontal={data.horizontal}
                      vertical={data.vertical}
                      setFilter={props.setFilter}
                      selectedFilter={props.selectedFilter}
                      setInputBoxValue={setInputBoxValue}
                      inputBoxValue={inputBoxValue}
                    />
                  )}

                  {/* border of the section */}
                  <BorderSection
                    horizontal={data.horizontal}
                    vertical={data.vertical}
                    show={
                      index !==
                      props.filterData?.filter(
                        (data) => data?.horizontal === "right"
                      )?.length -
                        1
                    }
                  />
                </div>
              ))}
          </CustomScrollBar>
        </div>
      </div>
    </div>
  );
}

const getTotalValueCount = (data, type, value) => {
  let count = 0;
  data &&
    Object.keys(data)?.forEach((key) => {
      if (data?.[key]?.[type] === value) {
        count++;
      }
    });
  return count;
};

const ArrayTextViewer = (props) => {
  const getClassName = (data) => {
    const selectedData = props.selectedFilter?.[props.data.name];

    if (
      (props.data.name === "PCTNo" &&
        selectedData?.includes(data?.split(" - ")[0])) ||
      selectedData?.includes(getValidStatus(data))
    ) {
      return props.horizontal === "left"
        ? "background-selected-card-gradient-e5e5e5-dark-left-to-right"
        : "background-selected-card-gradient-e5e5e5-dark-right-to-left";
    } else {
      return "";
    }
  };

  const getDataCy = (data, name) => {
    if (name === "status") {
      if (
        props.selectedFilter?.[props.data.name]?.includes(getValidStatus(data))
      ) {
        return `${props.data.dataCy}-selected-${data}`;
      } else {
        return `${props.data.dataCy}-select-${data}`;
      }
    }

    if (name === "PCTNo") {
      if (
        props.selectedFilter?.[props.data.name]?.includes(data.split(" - ")[0])
      ) {
        return `${props.data.dataCy}-selected-${data}`;
      } else {
        return `${props.data.dataCy}-select-${data}`;
      }
    }
  };

  return (
    <>
      {props.data.value.map((category, index) => (
        <div
          key={`${index}-${category}`}
          className={`inherit-parent-width padding-default margin-vertical-smaller font-size-medium text-uppercase cursor-pointer display-flex ${getClassName(
            category
          )}`}
          style={{
            justifyContent:
              props.horizontal === "right" ? "flex-end" : "flex-start",
            transform: getSkewData(props.horizontal, props.vertical),
          }}
          data-cy={getDataCy(category, props.data.name)}
          onClick={() => {
            let value = category;

            if (props.data.name === "PCTNo") {
              value = category.split(" - ")[0];
            }

            if (
              props.selectedFilter?.[props.data.name]?.includes(
                getValidStatus(value)
              )
            ) {
              props.setFilter({
                ...props.selectedFilter,
                [props.data.name]: props.selectedFilter?.[
                  props.data.name
                ]?.filter((element) => element !== getValidStatus(value)),
              });
            } else if (props.data.name === "PCTNo") {
              props.setFilter({
                ...props.selectedFilter,
                [props.data.name]: [getValidStatus(value)],
              });
            } else {
              props.setFilter({
                ...props.selectedFilter,
                [props.data.name]: [
                  ...props.selectedFilter?.[props.data.name],
                  getValidStatus(value),
                ],
              });
            }
          }}
        >
          <p>{transformValue(category)} </p>
          {!!props.data?.showCountValue && (
            <p className="padding-horizontal-default">
              {`(${getTotalValueCount(
                props.data?.getCountFrom,
                props.data?.name,
                getValidStatus(category)
              )})`}
            </p>
          )}
          {!!props.data?.showComplaintCount && (
            <p className="padding-horizontal-default">
              {`(${getComplaintStatusCount(
                props.complaints,
                getValidStatus(category)
              )})`}
            </p>
          )}
        </div>
      ))}
    </>
  );
};

const ArrayOfObjectViewer = (props) => {
  const getClassName = (data) => {
    if (isValidObject(data) && data.title) {
      if (props.selectedFilter?.[props.data.name]?.includes(data?.documentId)) {
        return props.horizontal === "left"
          ? "background-selected-card-gradient-e5e5e5-dark-left-to-right"
          : "background-selected-card-gradient-e5e5e5-dark-right-to-left";
      }
    } else {
      if (
        props.selectedFilter?.[props.data?.name]?.find(
          (element) => element === data?.english || element === data?.tamil
        )
      ) {
        return props.horizontal === "left"
          ? "background-selected-card-gradient-e5e5e5-dark-left-to-right"
          : "background-selected-card-gradient-e5e5e5-dark-right-to-left";
      }
    }
  };
  return (
    <>
      {props.data.value.map((subtext, index) => (
        <div
          style={{ transform: getSkewData(props.horizontal, props.vertical) }}
          key={`${index}-${subtext?.documentId}`}
          className={` margin-vertical-smaller inherit-parent-height padding-default font-size-medium text-uppercase text-overflow-ellipsis cursor-pointer  ${getClassName(
            subtext
          )}`}
          data-cy={
            isValidObject(subtext) && subtext.title
              ? props.selectedFilter?.[props.data.name]?.includes(
                  subtext.documentId
                )
                ? `${props.data.dataCy}-selected-${subtext.documentId}`
                : `${props.data.dataCy}-select-${subtext.documentId}`
              : props.selectedFilter?.[props.data?.name]?.find(
                  (element) =>
                    element === subtext?.english || element === subtext?.tamil
                )
              ? `${props.data.dataCy}-selected-${subtext.english}`
              : `${props.data.dataCy}-select-${subtext.english}`
          }
          onClick={() => {
            if (isValidObject(subtext) && subtext.title) {
              if (
                props.selectedFilter?.[props.data.name]?.includes(
                  subtext.documentId
                )
              ) {
                props.setFilter({
                  ...props.selectedFilter,
                  [props.data.name]: props.selectedFilter?.[
                    props.data.name
                  ]?.filter((element) => element !== subtext.documentId),
                });
              } else {
                props.setFilter({
                  ...props.selectedFilter,
                  [props.data.name]: [
                    ...props.selectedFilter?.[props.data.name],
                    subtext.documentId,
                  ],
                });
              }
            } else {
              if (
                props.selectedFilter?.[props.data?.name]?.find(
                  (element) =>
                    element === subtext?.english || element === subtext?.tamil
                )
              ) {
                props.setFilter({
                  ...props.selectedFilter,
                  [props.data?.name]: props.selectedFilter?.[
                    props.data?.name
                  ]?.filter((element) => {
                    if (
                      element !== subtext?.english &&
                      element !== subtext?.tamil
                    ) {
                      return true;
                    } else {
                      return false;
                    }
                  }),
                });
              } else {
                props.setFilter({
                  ...props.selectedFilter,
                  [props.data?.name]: [
                    ...props.selectedFilter?.[props.data?.name],
                    ...Object.values(subtext),
                  ],
                });
              }
            }
          }}
        >
          {isValidObject(subtext) && subtext.title && (
            <>{subtext.title || "NA"}</>
          )}
          {isValidObject(subtext) && !subtext.title && (
            <>
              {Object.values(subtext)?.map((element, index) => (
                <p
                  className="inherit-parent-width padding-bottom-small padding-top-smaller"
                  key={index}
                >
                  {element}
                </p>
              ))}
            </>
          )}
        </div>
      ))}
    </>
  );
};

const InputViewer = (props) => {
  const isValidEmployee = (phoneNumber) => {
    const employee = isValidObject(props.employees.data)
      ? Object.values(props.employees.data)?.filter((employee) =>
          employee.phoneNumber.includes(phoneNumber)
        )
      : [];
    if (isValidArray(employee)) {
      return {
        status: true,
        employeeId: employee?.[0]?.employeeId,
      };
    } else {
      return {
        status: false,
        message: "Employee not found",
      };
    }
  };
  const isValidPhoneNumberAndGetEmployee = (data, filterName) => {
    if (!data) {
      return;
    }
    if (filterName === "issueRaised" || filterName === "assignedTo") {
      if (data?.length === 10 && Regex.phoneNumber.test(data)) {
        return isValidEmployee(data);
      } else {
        return {
          status: false,
          message: "Phone number must be 10 digits",
        };
      }
    }
    if (filterName === "issueTakenBy") {
      if (data?.length === 10 && Regex.phoneNumber.test(data)) {
        return isValidEmployee(data);
      } else if (Regex.email.test(data)) {
        return;
      } else {
        return {
          status: false,
          message: "Please enter valid email or phone number",
        };
      }
    }
  };
  return (
    <>
      <div
        style={{ transform: getSkewData(props.horizontal, props.vertical) }}
        className={`display-flex flex-direction-row font-size-medium ${
          props.horizontal === "left" ? "" : "flex-justify-content-end"
        }  flex-align-items-center padding-default margin-vertical-default`}
        data-cy={`${props.data.dataCy}-input-box-section-${props.horizontal}`}
      >
        <p
          className={`text-uppercase text-align-${props.vertical} white-space-no-wrap`}
          style={{ width: "112px" }}
        >
          {props.data?.inputLabel}
        </p>
        <p className="text-align-center padding-horizontal-large">:</p>
        <input
          placeholder={props.data?.inputPlaceholder}
          onChange={(event) => {
            props.setInputBoxValue({
              ...props.inputBoxValue,
              [props.horizontal]: {
                ...props.inputBoxValue?.[props.horizontal],
                [event.target.name]: event.target.value,
              },
            });
            if (
              !props.data?.findEmployeeId ||
              props.data.name === "issueRaised"
            ) {
              return props.data?.inputOnChange(event.target.value);
            }

            const validation = isValidPhoneNumberAndGetEmployee(
              event.target.value,
              props.data.name
            );

            if (!props.data?.findEmployeeId && !!validation?.status) {
              props.data?.inputOnChange(event.target.value);
            }

            if (validation?.status === true) {
              props.data?.inputOnChange(validation?.employeeId);
            }
          }}
          data-cy={`${props.data.dataCy}-phoneNumber-input-box-${props.horizontal}`}
          className={`background-color-dark font-color-white border-none font-size-medium margin-right-medium`}
          name={props.data?.name}
          style={{
            minWidth: "50%",
          }}
          value={props.inputBoxValue?.[props.horizontal]?.[props.data?.name]}
        />

        {isValidPhoneNumberAndGetEmployee(
          props.inputBoxValue?.[props.horizontal]?.[props.data?.name],
          props.data.name
        )?.status === false ? (
          <div className="padding-horizontal-small cursor-pointer display-flex">
            <ToolTip
              text={
                isValidPhoneNumberAndGetEmployee(
                  props.inputBoxValue?.[props.horizontal]?.[props.data?.name],
                  props.data.name
                )?.message
              }
              theme={props.theme}
              dataCy={props.data?.dataCy}
            >
              <ErrorIcon width="16px" height="16px" />{" "}
            </ToolTip>
          </div>
        ) : (
          <div className="padding-horizontal-small cursor-pointer display-flex">
            <ErrorIcon width="16px" height="16px" color="#020d23" />{" "}
          </div>
        )}

        {(!props.inputBoxValue?.[props.horizontal]?.[props.data?.name] ||
          props.inputBoxValue?.[props.horizontal]?.[props.data?.name]
            ?.length === 0) && (
          <div className="padding-horizontal-smaller cursor-pointer display-flex">
            <SearchIcon
              color={props.theme === "dark" ? "white" : "#404040"}
              width="16px"
              height="16px"
            />
          </div>
        )}

        {typeof props.inputBoxValue?.[props.horizontal]?.[props.data?.name] ===
          "string" &&
          props.inputBoxValue?.[props.horizontal]?.[props.data?.name]
            ?.length !== 0 && (
            <div
              className="padding-horizontal-smaller cursor-pointer display-flex"
              onClick={() => {
                props.setInputBoxValue({
                  ...props.inputBoxValue,
                  [props.horizontal]: {
                    ...props.inputBoxValue?.[props.horizontal],
                    [props.data?.name]: "",
                  },
                });

                props.setFilter({
                  ...props.selectedFilter,
                  [props.data?.name]: [],
                });
              }}
            >
              <CloseIcon color="#404040" width={16} height={16} />
            </div>
          )}
      </div>
    </>
  );
};

const DateRangeViewer = (props) => {
  return (
    <>
      <form
        style={{ transform: getSkewData(props.horizontal, props.vertical) }}
        data-cy={`${props.data.dataCy}-component`}
        onChange={(e) => {
          if (e.target.value !== "") {
            props.setFilter({
              ...props.selectedFilter,
              [props.data.name]: {
                ...props.selectedFilter?.[props.data.name],
                [e.target.name]: e.target.value,
                ...(e.target.name === "from" &&
                !props.selectedFilter?.[props.data.name]?.to
                  ? { to: new Date().toISOString().split("T")[0] }
                  : {}),

                ...(e.target.name === "to" &&
                !props.selectedFilter?.[props.data.name]?.from
                  ? { from: e.target.value }
                  : {}),
              },
            });
          } else {
            props.setFilter({
              ...props.selectedFilter,
              [props.data.name]: {
                from: null,
                to: null,
              },
            });
          }
        }}
      >
        <div
          className="display-flex padding-default margin-vertical-smaller flex-align-items-center"
          style={{
            justifyContent: "end",
          }}
        >
          <div className="font-size-medium">FROM</div>
          <div className="padding-horizontal-large">:</div>
          <div
            className="display-flex flex-direction-column"
            style={{
              justifyContent: "end",
            }}
          >
            <input
              name="from"
              data-cy="filter-from-input"
              className={`font-color-white background-color-dark calendar-icon-color-invert border-none inherit-parent-width font-size-medium font-family-aldrich-regular `}
              style={{
                width: "120px",
              }}
              value={props.selectedFilter?.[props.data.name]?.from || ""}
              max={
                props.selectedFilter?.[props.data.name]?.to ||
                new Date().toISOString().split("T")[0]
              }
              type="date"
            />
          </div>
        </div>

        <div
          className="display-flex padding-default margin-vertical-smaller flex-align-items-center"
          style={{
            justifyContent: "end",
          }}
        >
          <div className="font-size-medium">TO</div>
          <div className="padding-horizontal-large">:</div>
          <div className="display-flex flex-direction-column">
            <input
              name="to"
              data-cy="filter-to-input"
              className={`font-color-white background-color-dark calendar-icon-color-invert border-none inherit-parent-width font-size-medium font-family-aldrich-regular`}
              style={{
                width: "120px",
              }}
              value={props.selectedFilter?.[props.data.name]?.to || ""}
              min={props.selectedFilter?.[props.data.name]?.from || ""}
              max={new Date().toISOString().split("T")[0]}
              type="date"
            />
          </div>
        </div>
      </form>
    </>
  );
};

const BorderSection = (props) => {
  return (
    <>
      {props.show ? (
        <div
          style={{
            transform: getSkewData(props.horizontal, props.vertical),
            minHeight: "1px",
            maxHeight: "1px",
          }}
          className={`inherit-parent-width margin-top-large ${
            props.horizontal === "left"
              ? "background-selected-card-gradient-e5e5e5-dark-left-to-right"
              : "background-selected-card-gradient-e5e5e5-dark-right-to-left"
          }
             `}
        />
      ) : (
        <div className={`inherit-parent-width margin-vertical-large`} />
      )}
    </>
  );
};

const TitleSection = (props) => {
  return (
    <>
      <div
        style={{
          transform: getSkewData(props.horizontal, props.vertical),
          zIndex: 1,
        }}
        className={`inherit-parent-width text-uppercase font-size-small position-sticky top-0 padding-horizontal-default background-color-dark`}
        data-cy={`${props.data.title}-banner`}
      >
        <p className="margin-vertical-medium inherit-parent-width">
          {props.data.title}
        </p>
      </div>
    </>
  );
};

const getSkewData = (horizontal, vertical) => {
  if (horizontal === "right") {
    if (vertical === "top") {
      return "skewY(-2deg)";
    }
    if (vertical === "middle") {
      return "skewY(0deg)";
    }

    if (vertical === "bottom") {
      return "skewY(0deg)";
    }
  }

  if (horizontal === "left") {
    if (vertical === "top") {
      return "skewY(1.5deg)";
    }
    if (vertical === "middle") {
      return "skewY(0deg)";
    }

    if (vertical === "bottom") {
      return "skewY(-1.5deg)";
    }
  }
};
