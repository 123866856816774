import React, { useState } from "react";
import ControlButton from "../ControlButton/ControlButton";
import CustomScrollBar from "../CustomScrollBar/CustomScrollBar";

export default function LocationsPreview(props) {
  const [activeOption, setActiveOption] = useState("OVERVIEW");
  const dateRange = generateDateRange(
    props.selectedTable.filter.dateRange.from,
    props.selectedTable.filter.dateRange.to
  );

  return (
    <div
      data-cy="location-modal"
      className={`modal-frame background-color-dark inherit-parent-height inherit-parent-width display-flex padding-larger min-width-170px `}
    >
      {/* Header-section */}
      <div
        className="inherit-parent-height padding-right-medium display-flex flex-direction-column"
        data-cy="header-section"
      >
        {["OVERVIEW", "WORKFORCE", "MORE INFO"].map((header, index) => (
          <ControlButton
            key={index}
            text={header}
            selected={activeOption === header}
            onClick={() => {
              setActiveOption(header);
            }}
            theme={props.theme}
            dataCy={
              activeOption === header
                ? `header-selected-${header?.toLowerCase()}`
                : `header-select-${header?.toLowerCase()}`
            }
          />
        ))}
      </div>
      <div
        style={{
          marginLeft: "48px",
        }}
        className={`parent-width-sub-156px inherit-parent-height display-flex flex-direction-column font-size-default position-relative `}
        data-cy="location-body-section"
      >
        <CustomScrollBar horizontal="left">
          <div
            style={{
              zIndex: 1,
            }}
            className="inherit-parent-width padding-top-default padding-bottom-default margin-bottom-medium text-align-left display-flex flex-direction-column position-sticky top-0 background-color-dark"
          >
            <div
              className="font-size-larger text-uppercase padding-bottom-default"
              data-cy={props.locationData?.location || "N/A"}
            >
              {props.locationData?.location || "N/A"}
            </div>
            <div
              className="font-size-default text-uppercase padding-bottom-default"
              data-cy={props.locationData?.PCTNo || "N/A"}
            >
              PCT {props.locationData?.PCTNo || "N/A"} , ZONE{" "}
              {props.locationData?.zone || "N/A"}
            </div>
          </div>

          {/* location choice */}
          {activeOption === "OVERVIEW" && (
            <div
              style={{
                height: "calc(100% - 90px)",
              }}
              className="inherit-parent-width display-flex flex-direction-column font-size-default"
              data-cy="location-description-section"
            >
              {[
                {
                  title: "ZONE",
                  value: props.locationData?.zone || "N/A",
                },
                {
                  title: "WARD",
                  value: props.locationData?.ward || "N/A",
                },
                {
                  title: "PCT NUMBER",
                  value: props.locationData?.PCTNo || "N/A",
                },
              ].map((data, index) => (
                <div
                  className="width-50-percentage"
                  key={`${index}-${data?.title}`}
                >
                  <Fields title={data?.title} data={data?.value} />
                </div>
              ))}
            </div>
          )}

          {activeOption === "WORKFORCE" && (
            <div
              style={{
                height: "calc(100% - 90px)",
              }}
              className="inherit-parent-width display-flex flex-direction-column font-size-default"
              data-cy="location-description-section"
            >
              <Fields
                title={"DATE RANGE"}
                data={`${props.selectedTable.filter.dateRange.from} - ${props.selectedTable.filter.dateRange.to}`}
              />
              <div>
                <div className="display-flex padding-top-larger padding-bottom-large">
                  {[
                    { title: "DATE", style: { width: "180px" } },
                    {
                      title: "JANITOR",
                      style: { width: "256px", paddingLeft: "16px" },
                    },
                    {
                      title: "SUPERVISOR",
                      style: { width: "256px", paddingLeft: "16px" },
                    },
                  ].map((data, index) => (
                    <div key={index} style={data.style} className="">
                      {data.title}
                    </div>
                  ))}
                </div>
              </div>
              {dateRange.map((date, index) => (
                <div className="display-flex" key={index}>
                  <div
                    style={{ width: "180px" }}
                    className="white-space-no-wrap padding-top-large"
                  >
                    {date}
                  </div>

                  <RoleData
                    date={date}
                    roleName="Janitor"
                    selectedSummaryData={props.selectedSummaryData}
                    roles={Object.values(props.roles)}
                  />

                  <RoleData
                    date={date}
                    roleName="Supervisor"
                    selectedSummaryData={props.selectedSummaryData}
                    roles={Object.values(props.roles)}
                  />
                </div>
              ))}
            </div>
          )}

          {/* complaint choice */}
          {activeOption === "MORE INFO" && (
            <div
              className="inherit-parent-width  overflow-scroll"
              data-cy="location-moreInfo-option"
              style={{
                height: "calc(100% - 90px)",
              }}
            >
              <div className="display-flex flex-justify-content-space-between padding-top-larger">
                {[
                  {
                    title: "COMPLAINT",
                    description: "View the selected location's complaints",
                    "data-cy": "complaint-option",
                    onClick: () => {
                      props.setSelectedTable(
                        "COMPLAINTS",
                        props.locationData?.documentId
                      );
                    },
                  },
                  // {
                  //   title: "PROCEDURE",
                  //   description: "View the selected location's procedures",
                  // "data-cy":"procedure-option",
                  //   onClick: () => {
                  //     props.setSelectedTable(
                  //       "PROCEDURES",
                  //       props.locationData?.documentId
                  //     );
                  //   },
                  // },
                ].map((data, index) => (
                  <div key={index}>
                    <MoreInfoCard data={data} onClick={data.onClick} />
                  </div>
                ))}
              </div>
            </div>
          )}
        </CustomScrollBar>
      </div>
    </div>
  );
}

const Fields = (props) => {
  return (
    <div className="display-flex flex-justify-content-space-between padding-vertical-medium">
      <div className="display-flex">
        <div
          className="white-space-no-wrap"
          style={{ width: "180px" }}
          data-cy={`${props.title}`}
        >
          {props.title}
        </div>
        <div className="padding-horizontal-medium">:</div>
      </div>
      <div
        className="text-align-left inherit-parent-width white-space-no-wrap text-uppercase"
        data-cy={props.dataCy || props.data}
      >
        {props.data}
      </div>
    </div>
  );
};

const MoreInfoCard = (props) => {
  return (
    <div
      data-cy={props.data?.["data-cy"]}
      style={{ width: "296px", height: "128px" }}
      className="display-flex flex-align-items-center margin-left-default margin-right-default flex-justify-content-center flex-direction-column border-1px-e5e5e5 cursor-pointer"
      onClick={props.onClick}
    >
      <div className="padding-left-default padding-right-default">
        {props.data.title}
      </div>
      <div className="padding-top-large padding-left-default padding-right-default text-align-center">
        {props.data.description}
      </div>
    </div>
  );
};

const SquareStatus = (props) => {
  return (
    <div
      style={{
        width: "10px",
        height: "10px",
        padding: "5px",
        backgroundColor: `${
          // #FF4C4C=red
          // ##00892F=green
          // #C2C2C2=grey
          props.value === 0 ? "#FF4C4C" : props.value === 1 ? "#00892F" : "grey"
        }`,
      }}
    />
  );
};

function generateDateRange(fromDate, toDate) {
  let startDate = new Date(fromDate);
  let endDate = new Date(toDate);

  let dateList = [];

  while (startDate <= endDate) {
    let day = String(startDate.getDate()).padStart(2, "0");
    let month = String(startDate.getMonth() + 1).padStart(2, "0");
    let year = startDate.getFullYear();
    dateList.push(`${day}-${month}-${year}`);

    startDate.setDate(startDate.getDate() + 1);
  }

  return dateList;
}

function getDateComponents(dateString, component) {
  let [day, month, year] = dateString.split("-");

  day = parseInt(day, 10);
  month = parseInt(month, 10);
  year = parseInt(year, 10);

  switch (component.toLowerCase()) {
    case "date":
      return day;
    case "month":
      return month;
    case "year":
      return year;
    default:
      return null;
  }
}

const RoleData = ({ date, roleName, selectedSummaryData, roles }) => {
  const role = roles.find((role) => role.name === roleName);
  const documentId = role?.documentId;
  const interval = role?.attendance?.capture?.interval || 0;

  const roleData =
    selectedSummaryData?.dayCount?.[getDateComponents(date, "year")]?.[
      getDateComponents(date, "month")
    ]?.[getDateComponents(date, "date")]?.[documentId];

  const renderData = roleData?.length
    ? roleData?.length < interval
      ? [
          ...roleData,
          ...Array(Math.max(interval - roleData?.length, 0)).fill(null),
        ]
      : roleData
    : Array(interval).fill(null);

  const isSingleRow = renderData?.length <= 8;

  if (isSingleRow) {
    return (
      <div
        style={{
          width: "256px",
        }}
        className="display-flex padding-left-large padding-top-large"
      >
        {renderData.map((data, index) => (
          <div className="padding-right-default display-flex" key={index}>
            <SquareStatus value={data} />
          </div>
        ))}
      </div>
    );
  } else {
    const firstRowData = renderData?.slice(0, 8);
    const secondRowData = renderData?.slice(8);

    return (
      <div
        style={{
          width: "256px",
        }}
        className="padding-left-large padding-top-large"
      >
        <div className="display-flex">
          {firstRowData?.map((data, index) => (
            <div className="padding-right-default display-flex" key={index}>
              <SquareStatus value={data} />
            </div>
          ))}
        </div>
        <div className="display-flex padding-top-small">
          {secondRowData?.map((data, index) => (
            <div
              className="padding-right-default padding-top-default display-flex"
              key={index}
            >
              <SquareStatus value={data} />
            </div>
          ))}
        </div>
      </div>
    );
  }
};
