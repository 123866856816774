import { all, put, takeEvery } from "@redux-saga/core/effects";
import {
  createNewDevice,
  createNewPicture,
  editPicture,
  getPictureStatusData,
  reloadPictureUpload,
} from "../../Services/database";
import store from "../store";
import { getFileUrl } from "../../Services/storage";
import { isValidObject } from "../../Services/validators";
import { setErrorStatus, setSuccessStatus } from "../status/action";

export const actionTypes = {
  CREATE_PICTURE: "CREATE_PICTURE",
  EDIT_PICTURE: "EDIT_PICTURE",
  CREATE_DEVICE: "CREATE_DEVICE",
  PUT_REFERENCE_PICTURE: "PUT_REFERENCE_PICTURE",
  PUT_CAPTURED_PICTURE: "PUT_CAPTURED_PICTURE",
  GET_PICTURE_STATUS: "GET_PICTURE_STATUS",
  PUT_DEVICES: "PUT_DEVICES",
  RETRY_PICTURE_UPLOAD_TO_DEVICES: "RETRY_PICTURE_UPLOAD_TO_DEVICES",
};

function* createPictureWorker(action) {
  try {
    yield setSurveillanceLoading(true);

    yield createNewPicture(action.payload.data, action.payload.asset);

    setSuccessStatus("Picture added successfully");

    yield setSurveillanceLoading(false);
  } catch (error) {
    yield setSurveillanceLoading(false);
    console.error("createPictureWorker", error);
  }
}
function* editPictureWorker(action) {
  try {
    yield setSurveillanceLoading(true);
    yield createNewPicture(action.payload.data, action.payload.asset);
    yield editPicture(action.payload.documentId);
    setSuccessStatus("Picture edited successfully");
    yield setSurveillanceLoading(false);
  } catch (error) {
    yield setSurveillanceLoading(false);
    console.error("createPictureWorker", error);
  }
}

function* createDeviceWorker(action) {
  try {
    yield setSurveillanceLoading(true);

    let deviceData = action.payload.data;

    const selectedLocation = Object.values(
      store.getState().locations.data
    )?.find((data) => data.PCTNo === deviceData.locationId);

    delete deviceData.locationId;

    yield createNewDevice({
      ...deviceData,
      location: {
        id: selectedLocation.documentId,
        PCTNo: selectedLocation.PCTNo,
        address: selectedLocation.location,
        ward: selectedLocation.ward,
        zone: selectedLocation.zone,
      },
      updatedAt: +new Date(),
    });

    yield setSurveillanceLoading(false);
  } catch (error) {
    yield setSurveillanceLoading(false);
    console.error("createDeviceWorker", error);
  }
}

function* putReferencePictureWorker(action) {
  try {
    yield setSurveillanceLoading(true);

    yield put({
      type: "SET_REFERENCE_PICTURE",
      payload: {
        data: action.payload.data,
      },
    });

    const pictures = Object.values(action.payload.data);

    for (let i = 0; i < pictures.length; i++) {
      const fileSrc = yield getFileUrl(pictures[i].asset);
      yield put({
        type: "SET_REFERENCE_PICTURE_ASSET",
        payload: {
          data: {
            [pictures[i].documentId]: { ...pictures[i], fileSrc: fileSrc.url },
          },
        },
      });
    }

    yield setSurveillanceLoading(false);
  } catch (error) {
    yield setSurveillanceLoading(false);
    console.error("setReferencePictureWorker", error);
  }
}

function* putCapturedPictureWorker(action) {
  try {
    yield setSurveillanceLoading(true);

    yield put({
      type: "SET_CAPTURED_PICTURE",
      payload: {
        data: action.payload.data,
      },
    });

    const capture = Object.values(action.payload.data);

    for (let i = 0; i < capture.length; i++) {
      const fileSrc = yield getFileUrl(capture[i].asset);
      yield put({
        type: "SET_CAPTURED_PICTURE_ASSET",
        payload: {
          data: {
            [capture[i].documentId]: { ...capture[i], fileSrc: fileSrc.url },
          },
        },
      });
    }

    yield setSurveillanceLoading(false);
  } catch (error) {
    yield setSurveillanceLoading(false);
    console.error("setCapturedPictureWorker", error);
  }
}

function* getPictureStatusWorker(action) {
  try {
    yield setSurveillanceLoading(true);

    const pictureData = Object.values(
      store.getState().surveillance.reference
    ).find((data) => data.employee.id === action.payload.employeeId);

    if (isValidObject(pictureData)) {
      const statusData = yield getPictureStatusData(pictureData.documentId);

      if (isValidObject(statusData)) {
        yield put({
          type: "SET_PICTURE_STATUS",
          payload: {
            data: {
              [action.payload.employeeId]: statusData,
            },
          },
        });
      }
    }

    yield setSurveillanceLoading(false);
  } catch (error) {
    yield setSurveillanceLoading(false);
    console.error("getPictureStatusWorker", error);
  }
}

function* putDevicesWorker(action) {
  try {
    yield put({
      type: "SET_DEVICES",
      payload: {
        data: action.payload.data,
      },
    });
  } catch (error) {
    yield setSurveillanceLoading(false);
    console.error("putDevicesWorker", error);
  }
}

function* retryPictureUploadToDevicesWorker(action) {
  try {
    yield setSurveillanceRetryLoading(true);

    yield reloadPictureUpload(action.payload.documentId);

    setSuccessStatus("Retrying successfully");

    yield setSurveillanceRetryLoading(false);
  } catch (error) {
    yield setSurveillanceRetryLoading(false);
    yield setErrorStatus(error);
    console.error("putDevicesWorker", error);
  }
}

export default function* surveillanceWatcher() {
  yield all([
    takeEvery("CREATE_PICTURE", createPictureWorker),
    takeEvery("EDIT_PICTURE", editPictureWorker),
    takeEvery("CREATE_DEVICE", createDeviceWorker),
    takeEvery("PUT_REFERENCE_PICTURE", putReferencePictureWorker),
    takeEvery("PUT_CAPTURED_PICTURE", putCapturedPictureWorker),
    takeEvery("GET_PICTURE_STATUS", getPictureStatusWorker),
    takeEvery("PUT_DEVICES", putDevicesWorker),
    takeEvery(
      "RETRY_PICTURE_UPLOAD_TO_DEVICES",
      retryPictureUploadToDevicesWorker
    ),
  ]);
}

function* setSurveillanceLoading(bool) {
  yield put({
    type: "SET_SURVEILLANCE_LOADING",
    payload: {
      loading: bool,
    },
  });
}

function* setSurveillanceRetryLoading(bool) {
  yield put({
    type: "SET_SURVEILLANCE_RETRY_LOADING",
    payload: {
      loading: bool,
    },
  });
}
