const complaintsReducer = (
  state = {
    loading: false,
    assetsLoading: false,
    exportLoading: false,
    paginationLoading: false,
    summaryLoading: false,
    summaryData: null,
    data: null,
    total: null,
  },
  action
) => {
  switch (action.type) {
    case "SET_COMPLAINTS_LOADING":
      return {
        ...state,
        loading: action.payload.loading,
      };
    case "SET_SUMMARY_LOADING":
      return {
        ...state,
        loading: action.payload.loading,
      };

    case "SET_COMPLAINTS_PAGINATION_LOADING":
      return {
        ...state,
        paginationLoading: action.payload.loading,
      };

    case "SET_COMPLAINT_COUNT_DATA":
      return {
        ...state,
        summaryData: action.payload.data,
      };

    case "SET_COMPLAINTS_EXPORT_LOADING":
      return {
        ...state,
        exportLoading: action.payload.loading,
      };

    case "SET_COMPLAINTS_ASSETS_LOADING":
      return {
        ...state,
        assetsLoading: action.payload.assetsLoading,
      };

    case "SET_COMPLAINTS_DATA":
      return {
        ...state,
        data: action.payload.data,
      };

    case "ADD_COMPLAINTS_DATA":
      return {
        ...state,
        data: { ...state.data, ...action.payload.data },
      };

    case "SET_TOTAL_COMPLIANT_COUNT":
      return {
        ...state,
        total: action.payload,
      };

    case "RESET":
      return {
        loading: false,
        assetsLoading: false,
        exportLoading: false,
        paginationLoading: false,
        summaryData: null,
        data: null,
        total: null,
      };

    default:
      return state;
  }
};
export default complaintsReducer;
