import React, { useMemo, useState } from "react";
import "./LocationTable.css";
import SearchFallBackIcon from "../../Assets/SearchFallBackIcon.svg";
import { isValidArray } from "../../Services/validators";
import Table from "../Table/Table";
import { capitalizeFirstLetter } from "../../Utils/constants";

export default function LocationTable(props) {
  const [sortConfig, setSortConfig] = useState({
    title: "",
    ascending: true,
  });

  const sortedLocations = useMemo(() => {
    if (!props.hits) {
      return [];
    }

    const data = [...props.hits];

    data.sort((a, b) => {
      let fieldA, fieldB;

      switch (sortConfig?.title) {
        case "MANPOWER":
          fieldA = a?.[locationField(sortConfig.title)];
          fieldB = b?.[locationField(sortConfig.title)];
          break;
        case "LAST EMP. CAP.":
          fieldA = a?.[locationField(sortConfig.title)];
          fieldB = b?.[locationField(sortConfig.title)];
          break;
        case "STATUS":
          fieldA = convertStatusToInt(a?.[locationField(sortConfig.title)]);
          fieldB = convertStatusToInt(b?.[locationField(sortConfig.title)]);
          break;
        case "WARD":
          fieldA = extractNumbers(a?.ward);
          fieldB = extractNumbers(b?.ward);
          break;
        case "PCT NO":
          fieldA = String(a?.PCTNo);
          fieldB = String(b?.PCTNo);
          break;
        default:
          fieldA = a?.[locationField(sortConfig.title)];
          fieldB = b?.[locationField(sortConfig.title)];
          break;
      }

      if (["PCT NO"].includes(sortConfig.title)) {
        return sortConfig.ascending
          ? fieldA.localeCompare(fieldB, undefined, { numeric: true })
          : fieldB.localeCompare(fieldA, undefined, { numeric: true });
      }

      if (
        [
          "WARD",
          "ZONE",
          "RESOLVED COMP.S",
          "UNRESOLVED COMP.S",
          "COMPLETED PROCEDURES",
          "STATUS",
          "MANPOWER",
          "LAST EMP. CAP.",
        ].includes(sortConfig.title)
      ) {
        if (typeof fieldA === "boolean" && typeof fieldB === "boolean") {
          return sortConfig.ascending
            ? Number(fieldA) - Number(fieldB)
            : Number(fieldB) - Number(fieldA);
        } else if (!isNaN(fieldA) && !isNaN(fieldB)) {
          return sortConfig.ascending ? fieldA - fieldB : fieldB - fieldA;
        }
      } else {
        const stringA = String(fieldA).toLowerCase();
        const stringB = String(fieldB).toLowerCase();
        if (stringA < stringB) {
          return sortConfig.ascending ? -1 : 1;
        }
        if (stringA > stringB) {
          return sortConfig.ascending ? 1 : -1;
        }
      }

      return 0;
    });

    return data;
  }, [sortConfig, props.hits]);

  const handleSort = (title) => {
    setSortConfig((prevConfig) => ({
      title,
      ascending: prevConfig.title === title ? !prevConfig.ascending : true,
    }));
  };

  const tableHeadings = [
    {
      title: "PCT NO",
      width: "70px",
    },
    {
      title: "WARD",
      width: "70px",
    },
    {
      title: "ZONE",
      width: "70px",
    },
    {
      title: "ADDRESS",
      width: "200px",
    },
    {
      title: "CATEGORY",
      width: "100px",
    },
    {
      title: "STATUS",
      width: "100px",
    },
    {
      title: "LAST EMP. CAP.",
      width: "120px",
      textAlign: "center",
    },
    {
      title: "MANPOWER",
      width: "100px",
      textAlign: "center",
    },
    {
      title: "RESOLVED COMP.S",
      width: "150px",
      textAlign: "center",
    },
    {
      title: "UNRESOLVED COMP.S",
      width: "150px",
      textAlign: "center",
    },
    // {
    //   title: "COMPLETED PROCEDURES",
    //   width: "200px",
    //   textAlign: "center",
    // },
  ];

  return (
    <div
      className="remaining-body-height-from-footer inherit-parent-height"
      style={{
        minWidth: "900px",
      }}
      data-cy={"locations-table"}
    >
      {isValidArray(sortedLocations) && (
        <Table
          showSummary={true}
          headers={tableHeadings}
          color={true}
          onScrollReachedEnd={() => {
            // if (props.locations.paginationLoading === false) {
            // props.getComplaintsNextPage();
            // }
          }}
          summaryClick={props.summaryClick}
          data={
            isValidArray(sortedLocations)
              ? sortedLocations?.map((data) => ({
                  documentId: data?.documentId,
                  PCTNo: data?.PCTNo?.toString()?.padStart(2, "0"),
                  ward: data?.ward,
                  zone: data?.zone?.toString()?.padStart(2, "0"),
                  location: data.location,
                  category: capitalizeFirstLetter(data?.category),
                  status: data?.status,
                  totalManPowerCapacity: data?.totalManPowerCapacity,
                  totalManPower: `${data?.totalManPower?.toString()} %`,
                  totalClosedCompliant: data?.totalClosedCompliant
                    ?.toString()
                    ?.padStart(2, "0"),
                  totalOpenCompliant: data?.totalOpenCompliant
                    ?.toString()
                    ?.padStart(2, "0"),
                  // totalCompletedProcedure: data?.totalCompletedProcedure
                  //   ?.toString()
                  //   ?.padStart(2, "0"),
                }))
              : []
          }
          onClick={(data) => {
            props.setSelectedTableId(data?.documentId);
          }}
          handleSort={(data) => {
            handleSort(data);
          }}
          sort={sortConfig}
          getField={locationField}
          theme={props.theme}
        />
      )}

      {!isValidArray(sortedLocations) && (
        <div
          className="parent-height-sub-42px inherit-parent-width font-size-default display-flex flex-direction-column flex-justify-content-center flex-align-items-center"
          data-cy="no-search-or-filter-fallback"
        >
          <img src={SearchFallBackIcon} alt="no filter result found" />
          <div className=" padding-top-larger">NO MATCHING RESULTS</div>
          <div className=" padding-top-large">
            There wasn’t any result matching to the search and filter
          </div>
        </div>
      )}
    </div>
  );
}

const locationField = (data) => {
  switch (data) {
    case "PCT NO":
      return "PCTNo";
    case "WARD":
      return "ward";
    case "ADDRESS":
      return "location";
    case "ZONE":
      return "zone";
    case "CATEGORY":
      return "category";
    case "STATUS":
      return "status";
    case "RESOLVED COMP.S":
      return "totalClosedCompliant";
    case "UNRESOLVED COMP.S":
      return "totalOpenCompliant";
    case "COMPLETED PROCEDURES":
      return "totalCompletedProcedure";
    case "MANPOWER":
      return "totalManPower";
    case "LAST EMP. CAP.":
      return "totalManPowerCapacity";
    default:
      return data.toLowerCase();
  }
};

const convertStatusToInt = (data) => {
  switch (data) {
    case "Optimal":
      return 1;
    case "Moderate":
      return 2;
    case "Critical":
      return 3;
    default:
      return 0;
  }
};

function extractNumbers(text) {
  return parseInt(text.replace(/\D/g, ""));
}
