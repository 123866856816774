import { put, takeEvery, all } from "redux-saga/effects";
import {
  loginWithEmailAndPassword,
  logout,
} from "../../Services/authentication";
import { setErrorStatus } from "../status/action";

export const authActionTypes = {
  LOGIN: "LOGIN",
  LOGOUT: "LOGOUT",
  ADD_AUTH_INFO: "ADD_AUTH_INFO",
  CLEAR_CREDENTIAL: "CLEAR_CREDENTIAL",
  SET_THEME: "SET_THEME",
};

function* loginWorker(action) {
  try {
    yield setAuthLoading(true);
    yield loginWithEmailAndPassword(action.payload);
    yield setAuthLoading(false);
  } catch (error) {
    yield setErrorStatus(error);
    yield setAuthLoading(false);
  }
}

function* logoutWorker() {
  try {
    yield setAuthLoading(true);
    yield logout();
    yield put({
      type: "RESET",
    });
  } catch (error) {
    yield setAuthLoading(false);
    yield setErrorStatus(error);
    yield setAuthLoading(false);
  }
}

function* clearCredentialWorker() {
  yield put({
    type: "CLEAR_AUTH_CREDENTIALS",
  });
}

function* setThemeWorker(action) {
  try {
    yield put({
      type: "PUT_THEME",
      payload: {
        theme: action.payload.theme,
      },
    });
  } catch (error) {}
}

function* addAuthInfoWorker(action) {
  try {
    yield put({
      type: "SET_AUTH_INFO",
      payload: action.payload,
    });
  } catch (error) {
    yield setErrorStatus(error);
  }
}

export default function* authWatcher() {
  yield all([
    takeEvery("LOGIN", loginWorker),
    takeEvery("LOGOUT", logoutWorker),
    takeEvery("CLEAR_CREDENTIAL", clearCredentialWorker),
    takeEvery("ADD_AUTH_INFO", addAuthInfoWorker),
    takeEvery("SET_THEME", setThemeWorker),
  ]);
}

function* setAuthLoading(value) {
  yield put({
    type: "SET_AUTH_LOADING",
    payload: {
      loading: value,
    },
  });
}
