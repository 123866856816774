import React, { useEffect, useState } from "react";
import { BackArrowIcon, MicIcon, StopIcon } from "../../Assets/assets";
import { setErrorStatus } from "../../Redux/status/action";
import { voiceRecorderTimeLimit } from "../../Services/config";
import useVoiceRecorder from "../../Services/useVoiceRecorder";
import { isValidArray, isValidObject } from "../../Services/validators";
import { dateAndTimeConverter, locationOptions } from "../../Utils/constants";
import Button from "../Button/Button";
import CarouselAndAudioPlayer from "../CarouselAndAudioPlayer/CarouselAndAudioPlayer";
import ControlButton from "../ControlButton/ControlButton";
import CropperModal from "../CropperModal/CropperModal";
import Modal from "../Modal/Modal";
import TrackProgress from "../TrackProgress/TrackProgress";
import AudioPlayer from "../AudioPlayer/AudioPlayer";
import CustomScrollBar from "../CustomScrollBar/CustomScrollBar";

export default function ComplaintsPreview(props) {
  const [activeOption, setActiveOption] = useState("LOCATION");
  const [accessByEmployees, setAccessByEmployees] = useState(null);
  const [file, setFile] = useState({ image: [], audio: null });
  const [croppedImage, setCroppedImage] = useState({ images: [] });

  useEffect(() => {
    if (isValidObject(props.employeeData) && props.compliantData?.locationId) {
      setAccessByEmployees([
        ...Object.values(props.employeeData).filter((employee) => {
          const foundEmployees = [];
          if (
            isValidObject(employee?.connected) &&
            isValidArray(employee?.location)
          ) {
            if (
              employee?.location?.find(
                (locationId) => locationId === props.compliantData?.locationId
              )
            ) {
              foundEmployees.push(employee);
            }
          }

          return foundEmployees;
        }),
      ]);
    }

    // eslint-disable-next-line
  }, [props.compliantData?.locationId]);

  useEffect(() => {
    if (
      activeOption === "UPDATE" &&
      props.compliantData.status.currentStatus === "CLOSED"
    ) {
      setActiveOption("MANAGE");
    }
    // eslint-disable-next-line
  }, [props.compliantData]);

  return (
    <div
      data-cy="compliant-modal"
      className={` modal-frame background-color-dark inherit-parent-height inherit-parent-width display-flex padding-larger min-width-170px `}
    >
      {/* Header-section */}
      <div
        className="inherit-parent-height padding-right-medium display-flex flex-direction-column"
        data-cy="header-section"
      >
        {["LOCATION", "COMPLAINT", "MANAGE"].map((header, index) => (
          <ControlButton
            key={index}
            text={header}
            selected={
              (activeOption === "VERIFY" ||
                activeOption === "UPDATE" ||
                activeOption === "COMPLAINT-UPDATES") &&
              header === "MANAGE"
                ? true
                : activeOption === header
            }
            onClick={() => {
              setActiveOption(header);
            }}
            theme={props.theme}
            dataCy={
              activeOption === header
                ? `header-selected-${header?.toLowerCase()}`
                : `header-select-${header?.toLowerCase()}`
            }
          />
        ))}
      </div>

      <div
        style={{
          marginLeft: "48px",
        }}
        className="parent-width-sub-156px inherit-parent-height display-flex flex-direction-column padding-left-medium font-size-default position-relative"
        data-cy="complaints-body-section"
      >
        <CustomScrollBar horizontal="left">
          <div className="inherit-parent-width padding-top-default padding-bottom-default margin-bottom-medium text-align-left display-flex flex-direction-column  position-sticky top-0 background-color-dark">
            <div
              className="font-size-larger text-uppercase padding-bottom-default"
              data-cy={props.compliantData?.documentId || "N/A"}
            >
              {props.compliantData?.documentId || "N/A"}
            </div>
            <div
              className="font-size-default text-uppercase padding-bottom-default"
              data-cy={props.locationData?.location || "N/A"}
            >
              {props.locationData?.location || "N/A"}
            </div>
            {(activeOption === "VERIFY" ||
              activeOption === "UPDATE" ||
              activeOption === "COMPLAINT-UPDATES") && (
              <div className="display-flex flex-align-items-center">
                <div
                  className="cursor-pointer padding-top-default width-fit-content"
                  onClick={() => {
                    setActiveOption("MANAGE");
                  }}
                >
                  <BackArrowIcon color={"white"} />
                </div>
                {activeOption === "COMPLAINT-UPDATES" && (
                  <div className="padding-left-large">UPDATES</div>
                )}
              </div>
            )}
          </div>

          {/* location choice */}
          {activeOption === "LOCATION" && (
            <div
              style={{
                height: "calc(100% - 90px)",
              }}
              className="inherit-parent-height inherit-parent-width display-flex flex-direction-column font-size-default"
              data-cy="location-description-section"
            >
              {locationOptions(props.locationData).map((data, index) => (
                <div
                  className="width-50-percentage"
                  key={`${index}-${data?.title}`}
                >
                  <Fields title={data?.title} data={data?.value} />
                </div>
              ))}
            </div>
          )}

          {/* complaint choice */}
          {activeOption === "COMPLAINT" && (
            <div
              style={{
                height: "calc(100% - 90px)",
              }}
              className="inherit-parent-width overflow-scroll"
              data-cy="complaint-description-section"
            >
              <ComplaintSelectCard
                complaintsAssetsLoading={props.complaintsAssetsLoading}
                theme={props.theme}
                setPreview={props.setPreviewImage}
                compliantData={props.compliantData}
              />
            </div>
          )}

          {/* manage choice */}
          {activeOption === "MANAGE" && (
            <div
              style={{
                height: "calc(100% - 90px)",
              }}
              className="inherit-parent-height inherit-parent-width display-flex flex-direction-column font-size-default"
              data-cy="manage-description-section"
            >
              <div className="flex-justify-content-space-between inherit-parent-height flex-direction-column">
                <div>
                  {[
                    props.compliantData?.takenOverBy
                      ? {
                          title: "UNDERTAKEN BY",
                          value: `${props.compliantData?.takenOverBy
                            ?.phoneNumber ||
                            props.compliantData?.takenOverBy?.email} , ${props
                            .compliantData?.takenOverBy?.employeeName ||
                            "N/A"}`,
                        }
                      : {},
                    props.compliantData?.takenOverBy
                      ? {
                          title: "UNDERTAKEN AT",
                          value:
                            `${dateAndTimeConverter(
                              props.compliantData?.status?.updatedAt,
                              "Time"
                            )}, ${dateAndTimeConverter(
                              props.compliantData?.status?.updatedAt,
                              "cardDate"
                            )}` || "N/A",
                          dataCy: dateAndTimeConverter(
                            props.compliantData?.status?.updatedAt,
                            "thirdDate"
                          ),
                        }
                      : {},
                    props.compliantData?.status?.currentStatus === "OPEN"
                      ? {
                          title: "ACCESS BY",
                          value: isValidArray(accessByEmployees)
                            ? accessByEmployees
                            : "N/A",
                          dataCy: "accessByEmployees",
                        }
                      : {},
                    props.compliantData?.status?.currentStatus === "CLOSED"
                      ? {
                          title: "CLOSED BY",
                          value: props.compliantData?.closedBy?.email || "N/A",
                        }
                      : {},
                    props.compliantData?.status?.currentStatus === "CLOSED"
                      ? {
                          title: "CLOSED AT",
                          value:
                            `${dateAndTimeConverter(
                              props.compliantData?.closure?.resolvedAt,
                              "Time"
                            )}, ${dateAndTimeConverter(
                              props.compliantData?.closure?.resolvedAt,
                              "cardDate"
                            )}` || "N/A",
                          dataCy: dateAndTimeConverter(
                            props.compliantData?.closure?.resolvedAt,
                            "thirdDate"
                          ),
                        }
                      : {},
                    isValidArray(props.compliantData?.updates)
                      ? {
                          title: "UPDATES",
                          dataCy: dateAndTimeConverter(
                            props.compliantData.updates?.[0]?.updatedAt,
                            "thirdDate"
                          ),
                          value: (
                            <div className="display-flex flex-justify-content-space-between flex-align-items-center">
                              <div>
                                <div
                                  style={{
                                    minWidth: "200px",
                                  }}
                                  className="text-uppercase width-90-percentage text-overflow-ellipsis text-over"
                                >
                                  {
                                    props.compliantData.updates?.[
                                      props.compliantData.updates.length - 1
                                    ]?.remarks
                                  }
                                </div>
                                <div
                                  className="padding-top-small font-size-small"
                                  data-cy={dateAndTimeConverter(
                                    props.compliantData.updates?.[0]?.updatedAt,
                                    "thirdDate"
                                  )}
                                >{`${dateAndTimeConverter(
                                  props.compliantData.updates?.[0]?.updatedAt,
                                  "Time"
                                )}, ${dateAndTimeConverter(
                                  props.compliantData.updates?.[0]?.updatedAt,
                                  "cardDate"
                                )}`}</div>
                              </div>
                              <div
                                onClick={() => {
                                  setActiveOption("COMPLAINT-UPDATES");
                                }}
                                data-cy="updates-view-more"
                                className="text-underline cursor-pointer font-size-small"
                              >
                                VIEW MORE
                              </div>
                            </div>
                          ),
                        }
                      : {},
                  ].map(
                    (data, index) =>
                      data.title && (
                        <div key={`${index}-${data?.title}`}>
                          <Fields
                            title={data?.title}
                            dataCy={data?.dataCy || false}
                            data={
                              isValidArray(data?.value) ? (
                                <div
                                  className="display-flex flex-direction-column inherit-parent-width overflow-scroll padding-bottom-medium"
                                  style={{
                                    maxHeight: "172px",
                                  }}
                                >
                                  {data?.value?.map((element, index) => (
                                    <div
                                      className={` display-flex text-uppercase ${
                                        index !== 0 ? "padding-top-default" : ""
                                      } ${
                                        index !== data?.value?.length - 1
                                          ? "padding-bottom-default"
                                          : ""
                                      }`}
                                      key={index}
                                      data-cy={`${element?.phoneNumber} , ${element?.name}`}
                                    >
                                      <p
                                        style={{
                                          width: "132px",
                                          minWidth: "114px",
                                          maxWidth: "132px",
                                        }}
                                      >
                                        {element?.phoneNumber}
                                      </p>
                                      <p className="padding-horizontal-default">
                                        ,
                                      </p>
                                      <p className="white-space-no-wrap">
                                        {element?.name}
                                      </p>
                                    </div>
                                  ))}
                                </div>
                              ) : (
                                <div data-cy={data?.value}> {data?.value} </div>
                              )
                            }
                          />
                        </div>
                      )
                  )}
                  {props.compliantData?.status?.currentStatus !== "OPEN" && (
                    <>
                      <Fields
                        title="RESOLUTION MEDIA"
                        dataCy="carousel-section"
                        data={
                          <CarouselAndAudioPlayer
                            complaintsAssetsLoading={
                              props.complaintsAssetsLoading
                            }
                            fileSrc={props.compliantData?.closure?.fileSrc}
                            dataCy="manage-section"
                            theme={props.theme}
                            onImageClick={(data) => {
                              props.setPreviewImage(data);
                            }}
                            setErrorStatus={setErrorStatus}
                          />
                        }
                      />
                    </>
                  )}
                </div>

                <div className="display-flex">
                  {props.compliantData?.status?.currentStatus === "OPEN" &&
                    (!isValidObject(props.compliantData?.takenOverBy) ||
                      props.compliantData?.takenOverBy?.uid !==
                        props.auth.data.uid) && (
                      <div className="inherit-parent-width flex-justify-content-end">
                        <Button
                          boxShadow={false}
                          type="button"
                          variant="primary"
                          text="Takeover"
                          className="margin-right-small width-200px"
                          onClick={() => {
                            props.complaintTakeover(
                              props.compliantData?.documentId
                            );
                            setActiveOption("UPDATE");
                          }}
                          data-cy={"takeover-button"}
                        />
                      </div>
                    )}

                  {props.compliantData?.status?.currentStatus === "OPEN" &&
                    props.compliantData?.takenOverBy?.uid ===
                      props.auth.data.uid && (
                      <div className="inherit-parent-width flex-justify-content-end">
                        <Button
                          boxShadow={false}
                          type="button"
                          variant="primary"
                          text="FIX COMPLIANT"
                          className="margin-right-small width-200px"
                          onClick={() => {
                            setActiveOption("UPDATE");
                          }}
                          data-cy={"takeover-button"}
                        />
                      </div>
                    )}

                  {props.compliantData?.status?.currentStatus ===
                    "UNDER REVIEW" && (
                    <div className="inherit-parent-width flex-justify-content-end">
                      <Button
                        boxShadow={false}
                        type="button"
                        variant="primary"
                        text="Verify"
                        className={"margin-right-small width-200px"}
                        onClick={() => {
                          setActiveOption("VERIFY");
                        }}
                        data-cy={"verify-button"}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          {activeOption === "VERIFY" && (
            <div
              style={{
                height: "calc(100% - 90px)",
              }}
              className="inherit-parent-height inherit-parent-width display-flex flex-direction-column font-size-default"
              data-cy="manage-description-section"
            >
              {props.compliantData?.status?.currentStatus ===
                "UNDER REVIEW" && (
                <div className="inherit-parent-height inherit-parent-width flex-justify-content-center">
                  <div
                    style={{ paddingBottom: "32px" }}
                    className="display-flex flex-direction-column width-70-percentage inherit-parent-height flex-justify-content-center"
                  >
                    <div className="text-align-center padding-bottom-larger">
                      YOU CAN CLOSE THE COMPLAINT OR REOPEN IF THE MEDIA IS
                      INCORRECT
                    </div>
                    <div className="display-flex inherit-parent-width flex-justify-content-space-around">
                      <Button
                        boxShadow={false}
                        type="button"
                        variant="transparent"
                        text="Close Compliant"
                        className="border-1px-e5e5e5 margin-right-small width-224-px margin-right-larger"
                        onClick={() => {
                          props.verifyCompliant(
                            {
                              documentId: props.compliantData?.documentId,
                            },
                            "close"
                          );
                          setActiveOption("MANAGE");
                        }}
                        data-cy={"close-button"}
                      />
                      <Button
                        boxShadow={false}
                        type="button"
                        variant="transparent"
                        text="ReOpen"
                        className="border-1px-e5e5e5  margin-right-small width-224-px  margin-left-larger"
                        onClick={() => {
                          props.verifyCompliant(
                            {
                              documentId: props.compliantData?.documentId,
                            },
                            "open"
                          );
                          setActiveOption("MANAGE");
                        }}
                        data-cy={"open-button"}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}

          {activeOption === "UPDATE" && (
            <div
              style={{
                height: "calc(100% - 90px)",
              }}
              className="inherit-parent-height inherit-parent-width display-flex flex-direction-column font-size-default"
              data-cy="manage-description-section"
            >
              <div className="display-flex flex-justify-content-space-between inherit-parent-height flex-direction-column">
                <div className="width-50-percentage padding-vertical-default">
                  <Fields
                    title="UPLOAD FILES"
                    dataCy="upload-file-section"
                    data={
                      <div className="display-flex">
                        <CarouselAndAudioPlayer
                          complaintsAssetsLoading={
                            props.complaintsAssetsLoading
                          }
                          setCroppedImage={setCroppedImage}
                          fileSrc={croppedImage}
                          dataCy="update-section"
                          theme={props.theme}
                          setFile={setFile}
                          file={file}
                          add={true}
                          setErrorStatus={setErrorStatus}
                          onImageClick={(data) => {
                            props.setPreviewImage(data);
                          }}
                        />
                      </div>
                    }
                  />
                  <Fields
                    title="ADD VOICE NOTE"
                    data={
                      <StartRecording
                        file={file}
                        setFile={setFile}
                        theme={props.theme}
                      />
                    }
                    dataCy="add-voice-note-section"
                  />
                </div>
                <div className="inherit-parent-width flex-justify-content-end">
                  <Button
                    boxShadow={false}
                    type="button"
                    loading={props.compliantLoading}
                    variant="primary"
                    text="UPLOAD"
                    className="margin-right-small width-224-px margin-right-larger"
                    disabled={
                      isValidArray(croppedImage?.images) || file.audio
                        ? false
                        : true
                    }
                    onClick={() => {
                      props.fixComplaints({
                        documentId: props.compliantData?.documentId,
                        locationId: props.compliantData?.locationId,
                        proof: {
                          image: croppedImage.images,
                          audio: file.audio,
                        },
                      });
                    }}
                    data-cy="close-button"
                  />
                </div>
              </div>
            </div>
          )}

          {activeOption === "COMPLAINT-UPDATES" && (
            <div className="overflow-scroll hide-scroll-bar">
              <TrackProgress
                data={props.compliantData?.updates}
                setSelectedFolderIndex={(index) => {
                  props.onFolderOnClick(index);
                }}
                setPreview={(data) => {
                  props.setPreviewImage(data);
                }}
                theme={props.theme}
              />
            </div>
          )}
        </CustomScrollBar>
      </div>
      <Modal
        show={isValidArray(file?.image)}
        canIgnore={true}
        onClose={() => {
          setFile({ ...file, image: [] });
        }}
        width="inherit-parent-width"
        maxWidth="max-width-800px"
        background="false"
        boxShadow="false"
        borderRadius="false"
        height="inherit-parent-height max-height-500px"
      >
        <div
          data-cy="cropper-modal"
          className={`background-white inherit-parent-height border-radius-default box-shadow-default font-family-gilroy-regular font-color-secondary`}
        >
          <CropperModal
            theme={props.theme}
            className="border-radius-default"
            OnBlobCreate={(blob) => {
              const imageFile = new File([blob], `${+new Date()}.png`, {
                type: "image/png",
              });
              setCroppedImage({ images: [...croppedImage.images, imageFile] });
            }}
            file={file.image}
            setFile={() => {
              setFile({ ...file, image: [] });
            }}
          />
        </div>
      </Modal>
    </div>
  );
}

const ComplaintSelectCard = (props) => {
  const getRaisedBy = (issuedBy) => {
    if (issuedBy?.userDetails) {
      return `${issuedBy?.userDetails?.phoneNumber} , ${issuedBy?.userDetails?.name}`;
    }
    if (issuedBy?.sensorDetails) {
      return `${issuedBy?.sensorDetails?.name}`;
    }
  };
  return (
    <div
      className="inherit-parent-width display-flex flex-direction-column"
      key={props?.key}
      data-cy={`complaint-view-section`}
    >
      {[
        {
          title: "ISSUE",
          value: props.compliantData?.category || "N/A",
        },
        {
          title: "STATUS",
          value: props.compliantData?.status?.currentStatus || "N/A",
        },
        {
          title: "RAISED BY",
          value: getRaisedBy(props.compliantData?.issuedBy) || "N/A",
        },
        {
          title: "RAISED ON",
          value: props.compliantData?.createdAt
            ? `${dateAndTimeConverter(
                props.compliantData?.createdAt,
                "Time"
              )}, ${dateAndTimeConverter(
                props.compliantData?.createdAt,
                "cardDate"
              )}`
            : "N/A",
          dataCy: dateAndTimeConverter(
            props.compliantData?.createdAt,
            "thirdDate"
          ),
        },
        isValidArray(props.compliantData?.issuedBy?.linkedWith)
          ? {
              title: "LINKED WITH",
              value: (
                <div
                  className="display-flex flex-direction-column inherit-parent-width overflow-scroll"
                  style={{
                    maxHeight: "172px",
                  }}
                >
                  {props.compliantData?.issuedBy?.linkedWith.map(
                    (data, index) => (
                      <div
                        className={` display-flex text-uppercase ${
                          index !== 0 ? "padding-top-default" : ""
                        } ${
                          index !== data?.value?.length - 1
                            ? "padding-bottom-default"
                            : ""
                        }`}
                        key={index}
                      >
                        <p
                          style={{
                            width: "114px",
                            minWidth: "114px",
                            maxWidth: "114px",
                          }}
                        >
                          {data.userDetails?.phoneNumber}
                        </p>
                        <p className="padding-horizontal-default">,</p>
                        <p className="white-space-no-wrap">
                          {data.userDetails?.name}
                        </p>
                      </div>
                    )
                  )}
                </div>
              ),
              dataCy: "linkedWith",
            }
          : {},
      ].map((data, index) => (
        <div className="width-50-percentage" key={`${index}-${data?.issue}`}>
          {isValidObject(data) && (
            <Fields
              title={data?.title}
              data={data?.value}
              dataCy={data?.dataCy}
            />
          )}
        </div>
      ))}
      {
        <div className="width-50-percentage">
          <Fields
            title="COMPLAINT MEDIA"
            dataCy="complaint-carousel-section"
            data={
              <>
                {isValidObject(props.compliantData?.fileSrc) ? (
                  <CarouselAndAudioPlayer
                    complaintsAssetsLoading={props.complaintsAssetsLoading}
                    fileSrc={props.compliantData?.fileSrc}
                    dataCy="complaint-section"
                    theme={props.theme}
                    onImageClick={(data) => {
                      props.setPreview(data);
                    }}
                    setErrorStatus={setErrorStatus}
                  />
                ) : (
                  "N/A"
                )}
              </>
            }
          />
        </div>
      }
    </div>
  );
};

const Fields = (props) => {
  return (
    <div className="display-flex padding-vertical-medium">
      <div className="display-flex">
        <div
          className="white-space-no-wrap"
          style={{ width: "180px" }}
          data-cy={`${props.title}`}
        >
          {props.title}
        </div>
        <div className="padding-horizontal-medium">:</div>
      </div>
      <div
        className="text-align-left inherit-parent-width text-overflow-ellipsis text-uppercase"
        data-cy={props.dataCy || props.data}
        style={{
          minWidth: "fit-content",
          maxWidth: "380px",
        }}
      >
        {props.data}
      </div>
    </div>
  );
};

const StartRecording = (props) => {
  const [voiceRecorderControl, setVoiceRecorderControl] = useState(null);
  const { startRecording, saveRecording } = useVoiceRecorder();
  const [counter, setCounter] = useState(null);
  const [running, setRunning] = useState(false);
  const [recordedAudio, setRecordedAudio] = useState({
    audioBlob: false,
    audioURL: "",
  });

  useEffect(() => {
    let counterTimer;
    if (
      counter !== null &&
      typeof counter === "number" &&
      counter <= voiceRecorderTimeLimit &&
      running
    ) {
      counterTimer = setTimeout(() => {
        setCounter(counter + 1);
      }, 1000);
    } else {
      return () => clearTimeout(counterTimer);
    }
    if (counter === voiceRecorderTimeLimit) {
      setVoiceRecorderControl("stop");
      voiceRecorderHandler();
    }
    // eslint-disable-next-line
  }, [counter]);

  useEffect(() => {
    if (recordedAudio?.audioURL) {
      setVoiceRecorderControl(null);
    }
  }, [recordedAudio?.audioURL]);

  const voiceRecorderHandler = async () => {
    if (voiceRecorderControl === null || voiceRecorderControl === "cancel") {
      setVoiceRecorderControl("start");
      setCounter(0);
      setRunning(true);

      const res = await startRecording();
      if (res === false) {
        /**
         * voice recorder returns false is microphone is detected or any other issues in recording
         */
        setVoiceRecorderControl(null);
      }
    } else if (voiceRecorderControl === "start") {
      setVoiceRecorderControl("stop");
      setCounter(null);
      setRunning(false);
      const resBlob = await saveRecording();
      setRecordedAudio({
        audioBlob: resBlob,
        audioURL: URL.createObjectURL(resBlob),
      });

      const audioFile = new File([resBlob], `${+new Date()}.mp3`, {
        type: "audio/mp3",
      });

      props.setFile({
        ...props.file,
        audio: audioFile,
      });

      props.setClearAudio && props.setClearAudio(false);
    } else if (voiceRecorderControl === "stop") {
      setVoiceRecorderControl("cancel");
      setRecordedAudio({
        audioBlob: false,
        audioURL: "",
      });
    }
  };

  return (
    <div>
      {!recordedAudio?.audioURL && (
        <div className="flex-justify-content-space-between inherit-parent-width flex-align-items-center">
          <div className="display-flex flex-align-items-center">
            <div
              className="flex-place-children-page-center  border-radius-50-percentage"
              onClick={() => {
                voiceRecorderHandler();
              }}
              data-cy={`${
                voiceRecorderControl === "start"
                  ? "stop-record-button"
                  : "start-record-button"
              }`}
            >
              {voiceRecorderControl === "start" ? (
                <StopIcon color="white" />
              ) : (
                <MicIcon color="white" />
              )}
            </div>
            <div className="padding-left-large">
              {voiceRecorderControl === "start"
                ? "RECORDING..."
                : "TAP THE MIC TO RECORD AUDIO"}
            </div>
          </div>
          {voiceRecorderControl === "start" && (
            <div style={{ width: 50 }}>
              00:{counter?.toString().length === 1 ? "0" : ""}
              {counter}
            </div>
          )}
        </div>
      )}

      {recordedAudio?.audioURL && (
        <AudioPlayer
          theme={props.theme}
          className={"flex-basis-80-percentage"}
          closeOnClick={() => {
            setRecordedAudio({
              audioBlob: false,
              audioURL: "",
            });
            props.setFile({
              ...props.file,
              audio: null,
            });
          }}
          src={
            typeof recordedAudio.audioURL === "string"
              ? recordedAudio.audioURL
              : ""
          }
        />
      )}
    </div>
  );
};
